import * as React from 'react';

// import ui
import { PrimaryButton, Stack, Text } from '@fluentui/react';
import moment from 'moment';
import { IDeliveryDetailsResourceShortProps } from '../../../../props/orders/deliveryDetails';
import { IOrderProductProps } from '../../../../props/orders/product';
import OrderConfirmedImage from './../../../../assets/backgrounds/order-confirmed.jpg';
import styles from './style.module.scss';
import FooterComponent from '../../../../components/footer';
import { ProviderStore } from '../../../../context/global';
import GeneralConfig from '../../../../config';

interface IThankyouPageProps {
    context: ProviderStore;
    cart: IOrderProductProps[];
    deliveryDate: string;
    deliveryDetails?: IDeliveryDetailsResourceShortProps;
}

export default class ThankyouPage extends React.Component<IThankyouPageProps, {}> {
    constructor(props: IThankyouPageProps) {
        super(props);

        this.state = {};
    }

    private getDeliveryDetailsMessage = (details: IDeliveryDetailsResourceShortProps) => {
        return `
_*Delivery Details*_
Method: ${details.deliveryMethod.name}${details.deliveryTime ? ` at ${moment(details.deliveryTime, 'H').format('H A')}` : ''}
From: ${details.senderName} - ${details.senderPhone}
To: ${details.recipientName} - ${details.recipientPhone}
Address: ${details.recipientAddress}
Complimentary Card? ${details.giftCard ? 'Yes' : 'No'}
Message: ${details.message || '-'}`;
    }

    private generateToppingMessage = (product: IOrderProductProps) => {
        if (product.toppings && product.toppings.length > 0) {
            return `
*Toppings*:
${product.toppings.map((tp) => `${tp.topping.name} *x${tp.quantity}*`).join(', ')}`;
        } else {return '';}
    }

    private generateProductMessage = (product: IOrderProductProps) => {
        return `-----
_*${product.product.name}*_
*Box*: ${product.packaging.name}
*Flavours*:
${product.flavours.map((fl) => `${fl.flavour.name} *x${fl.quantity}*`).join('\n')}${this.generateToppingMessage(product)}${product.deliveryDetails ? '\n' + `${this.getDeliveryDetailsMessage(product.deliveryDetails)}` : ''}
-----`;
    }

    private generateMessage = () => {
        const {cart, deliveryDate, deliveryDetails} = this.props;
        if (deliveryDetails) {
        const message = `Hi Loure,
Please process my order below:

Delivery date: *${moment(deliveryDate).format("DD/MM/YYYY")}*
${deliveryDetails ? this.getDeliveryDetailsMessage(deliveryDetails) : ''}

${cart.map((c) => this.generateProductMessage(c)).join('\n\n')}
`;
            return message;
        } else {
            const message = `Hi Loure,
Please process my order below:

Delivery date: *${moment(deliveryDate).format("DD/MM/YYYY")}*

${cart.map((c) => this.generateProductMessage(c)).join('\n\n')}
`;

            return message;
        }
    }

    private generateWhatsAppUrl = () => {
        const message = this.generateMessage();
        return `https://wa.me/${GeneralConfig.whatsappPhone}?text=${encodeURIComponent(message)}`;
    }

    public componentDidMount = () => {
        const url = this.generateWhatsAppUrl();
        const newWindow = window.open(url);             
        if(newWindow == null || typeof(newWindow)=='undefined') {
            //window.location.href = url;
            window.open(url);
        }
    }

    private renderFooterComponent = () => {
        const url = this.generateWhatsAppUrl();
        let leftItems: JSX.Element = <Stack grow={1}>
            <PrimaryButton text={"Send Message"} href={url} target="_blank" />
        </Stack>

        return { leftItems };
    }

    public render() {
        const { cart, deliveryDate, deliveryDetails, context } = this.props;

        return (
            <Stack horizontalAlign='center' className={styles.thankyouPage} tokens={{ childrenGap: 15 }}>
                <img src={OrderConfirmedImage} style={{borderRadius: 8}} />
                <Stack tokens={{childrenGap: 20}} horizontalAlign={"center"}>
                    <Text className={styles.headerTitle} as={"h2"} variant={"xxLargePlus"}>Molto Bene!</Text>
                    <Text variant={"large"} style={{textAlign: "center"}}>Your order has been confirmed! Please click button below to finalise payment and reconfirm your order to our staff.</Text>
                </Stack>
                <FooterComponent context={context} steps={'selectPackage'} {...this.renderFooterComponent()} />
            </Stack>
        );
    }
}