import React from 'react';

// import ui
import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import { registerIcons, loadTheme } from '@fluentui/react/lib/Styling';

// import component
import HomePage from './pages';

import * as LucideIcon from 'lucide-react';
import Provider from './context/global';
import './styles/global.scss';
import './styles/fluentuioverride.scss';

registerIcons({
  icons: {
    ShoppingCart: <LucideIcon.ShoppingCart />,
    ChevronLeft: <LucideIcon.ChevronLeft />,
    PlusCircle: <LucideIcon.PlusCircle />,
    MinusCircle: <LucideIcon.MinusCircle />,
    StarIcon: <LucideIcon.Star />,
    Info: <LucideIcon.Info />,
    Edit: <LucideIcon.Edit />,
    Home: <LucideIcon.Home />,
    POI: <LucideIcon.MapPin />,
    Add: <LucideIcon.Plus />,
    Remove: <LucideIcon.Minus />,
    Snowflake: <LucideIcon.Snowflake />,
    Squalls: <LucideIcon.Sprout />,
  },
});

// update theme
loadTheme({palette: {
  themePrimary: '#3e5526',
  themeLighterAlt: '#f6f8f3',
  themeLighter: '#dae4d0',
  themeLight: '#bbccaa',
  themeTertiary: '#809866',
  themeSecondary: '#506936',
  themeDarkAlt: '#384c22',
  themeDark: '#2f401d',
  themeDarker: '#232f15',
  neutralLighterAlt: '#f8f5ec',
  neutralLighter: '#f4f1e8',
  neutralLight: '#eae8de',
  neutralQuaternaryAlt: '#dad8cf',
  neutralQuaternary: '#d0cec6',
  neutralTertiaryAlt: '#c8c6be',
  neutralTertiary: '#bab8b7',
  neutralSecondary: '#a3a2a0',
  neutralPrimaryAlt: '#8d8b8a',
  neutralPrimary: '#323130',
  neutralDark: '#605e5d',
  black: '#494847',
  white: '#fff',
}
});

function App() {
  return (
    <Provider>
      <BrowserRouter>
        <Routes>
          <Route path='/*' element={<HomePage />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
