import Request from './../request';
import { IDeliveryMethodResourceShort } from '../../props/data';

// import interfaces

const DeliveryMethodService = {
  retrieve: async (): Promise<IDeliveryMethodResourceShort[]> => {
    try {
      const results = await Request.get(`/api/deliveryMethods`);
      return results;
    } catch (e) {
      throw e;
    }
  }
};

export default DeliveryMethodService;
