import * as React from 'react';

// import ui
import { DefaultButton, Stack, Text } from '@fluentui/react';
import { ProviderStore } from '../../context/global';
import FooterComponent from '../../components/footer';
import ModalAddDelivery from '../selectDelivery/surfaces/addDelivery';
import ModalUpdateDelivery from '../selectDelivery/surfaces/updateDelivery';

interface IDeliveryDetailsProps {
  context: ProviderStore;
}

interface IDeliveryDetailsState {
  updateDelivery?: JSX.Element;
  data?: {
    receiver?: string;
    sender?: string;
    address?: string;
    gift?: string;
    method?: string;
  };
}

export default class DeliveryDetails extends React.Component<IDeliveryDetailsProps, IDeliveryDetailsState> {
  constructor(props: IDeliveryDetailsProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { context } = this.props;
    const { updateDelivery } = this.state;
    return (
      <Stack tokens={{ childrenGap: 10 }}>
        {updateDelivery && updateDelivery}
        <Text variant='xLarge'>DELIVERY DETAILS</Text>
        <Stack.Item>
          <DefaultButton
            text='Update Details'
            styles={{ root: { borderRadius: 5 } }}
            iconProps={{ iconName: 'Edit' }}
            onClick={() => {
              this.setState({
                updateDelivery: (
                  <ModalUpdateDelivery onDismiss={() => this.setState({ updateDelivery: undefined })} />
                ),
              });
            }}
          />
        </Stack.Item>
        <Stack tokens={{ childrenGap: 10 }}>
          <Stack>
            <Text styles={{ root: { fontWeight: 500 } }}>Delivery Method</Text>
            <Text>Pick up on 24/02/2022 at 14 PM</Text>
          </Stack>
          <Stack>
            <Text styles={{ root: { fontWeight: 500 } }}>From</Text>
            <Text>asda (0812908374)</Text>
          </Stack>
          <Stack>
            <Text styles={{ root: { fontWeight: 500 } }}>To</Text>
            <Text>asda (0812908374)</Text>
          </Stack>
          <Stack>
            <Text styles={{ root: { fontWeight: 500 } }}>Address</Text>
            <Text>-</Text>
          </Stack>
          <Stack>
            <Text styles={{ root: { fontWeight: 500 } }}>Complimentary Gift Card</Text>
            <Text>No</Text>
          </Stack>
        </Stack>
        <FooterComponent context={context} steps={'deliveryDetails'} />
      </Stack>
    );
  }
}
