import Cookie from 'js-cookie';
import { IOrderProps } from '../../props/orders';
import { IOrderProductProps } from '../../props/orders/product';
import Request from '../request';

const OrderService = {
    submit: async (data: FormData) => {
        try {
            const result = await Request.post(`/api/orders`, data, 'multipart/form-data');
            return result;
        } catch (e) {
            throw e;
        }
    }
};

export default OrderService;