import React, { createContext, Component } from 'react';
import { ItemType, StepsType } from '../props/data';

export interface ProviderState {
  steps: StepsType;
  deliveryDate?: Date;
  items: ItemType[];
}

interface UpdateStateArg {
  key: keyof ProviderState;
  value: any;
}

export interface ProviderStore {
  globalState: ProviderState;
  updateGlobalState: (arg: UpdateStateArg, callback?: () => void) => void;
}

const Context = createContext({} as ProviderStore); // type assertion on empty object

export default class Provider extends Component<{}, ProviderState> {
  public readonly state: ProviderState = {
    steps: 'greeting',
    items: [],
  };

  private updateGlobalState = ({ key, value }: UpdateStateArg, callback?: () => void) =>
    this.setState({ ...this.state, [key]: value }, callback);

  public render() {
    const { children } = this.props;
    const store: ProviderStore = {
      globalState: this.state,
      updateGlobalState: this.updateGlobalState,
    };

    return <Context.Provider value={store}>{children}</Context.Provider>;
  }
}

const Consumer = Context.Consumer;

export { Consumer };
