import * as React from 'react';
import { Routes, Route } from 'react-router-dom';

// import ui
import { initializeIcons, Spinner, Stack } from '@fluentui/react';

// import component
import MenubarComponent from '../components/menubar';
import FooterComponent from '../components/footer';

// import styles
import styles from './styles.module.scss';

// import context
import { Consumer } from '../context/global';

// import config
import config from '../utils/config.json';

// import page
import Greetings from './greetings';
import SelectPackage from './selectPackage';
import Order from './orders';
import CartPage from './cart';
import CheckoutPage from './checkout';
import SelectDeliveryType from './selectDelivery';
import DeliveryDetails from './deliveryDetails';
import ProductService from '../services/products/index';
import PackagingService from '../services/packagings';
import FlavourService from '../services/flavours';
import { IProductResourceShort } from '../props/products';
import { IFlavourResourceShort } from '../props/flavours';
import { IPackagingResourceShort } from '../props/packagings';
import { IAvailableDataProps } from '../props/general';
import DeliveryMethodService from '../services/deliveryMethods';
import ToppingService from '../services/toppings';

interface IHomePageProps {}

interface IHomePageState extends IAvailableDataProps {
  loaded?: boolean;
}

initializeIcons();

export default class HomePage extends React.Component<IHomePageProps, IHomePageState> {
  constructor(props: IHomePageProps) {
    super(props);

    this.state = {
      toppings: [],
      products: [],
      packagings: [],
      flavours: [],
      deliveryMethods: []
    };
  }

  private init = async () => {
    const [products, packagings, flavours, deliveryMethods, toppings] = await Promise.all([ProductService.retrieve(), PackagingService.retrieve(), FlavourService.retrieve(), DeliveryMethodService.retrieve(), ToppingService.retrieve()]);
    this.setState({
      loaded: true,
      products: products.filter((pr) => pr.packagings && pr.packagings.length > 0),
      packagings,
      flavours,
      deliveryMethods,
      toppings
    });
  }

  public componentDidMount = () => {
    this.init();
  }

  public render() {
    const {products, packagings, flavours, deliveryMethods, toppings, loaded} = this.state;
    const data:IAvailableDataProps = {products, packagings, flavours, deliveryMethods, toppings};

    return (
      <Consumer>
        {(context) => (
          <Stack className={styles.container}>
            <MenubarComponent context={context} />
            <Stack className={`${styles.content} lou-of-container`}>
              <Stack className='lou-of-content'>
                {!loaded ? <Spinner /> : null}
                {loaded ? <Routes>
                  <Route path={config.url.greetings.index} element={<Greetings context={context} />} />
                  <Route path={config.url.selectPackage.index} element={<SelectPackage data={data} context={context} />} />
                  <Route path={config.url.orders.index} element={<Order context={context} />} />
                  <Route path={config.url.cart.index} element={<CartPage context={context} />} />
                  <Route path={config.url.checkout.index} element={<CheckoutPage data={data} context={context} />} />
                  <Route
                    path={config.url.deliveryType.index}
                    element={<SelectDeliveryType context={context} />}
                  />
                  <Route
                    path={config.url.deliveryDetails.index}
                    element={<DeliveryDetails context={context} />}
                  />
                </Routes> : null}
              </Stack>
            </Stack>
          </Stack>
        )}
      </Consumer>
    );
  }
}
