import * as React from 'react';

// import ui
import {
  ActionButton,
  DetailsList,
  IColumn,
  Icon,
  IconButton,
  Label,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
  TooltipDelay,
  TooltipHost,
} from '@fluentui/react';
import { ProviderStore } from '../../context/global';

// import styles
import styles from './styles.module.scss';
import FooterComponent from '../../components/footer';

import img from '../../assets/products/loure1.png';
import { NavLink } from 'react-router-dom';
import { PrimaryButton } from '@fluentui/react';
import { IOrderProductProps } from '../../props/orders/product';
import CartService from '../../services/cart';
import GeneralService from '../../services/general';
import CartComponent from '../../components/cart';

interface ICartPageProps {
  context: ProviderStore;
}

interface ICartPageState {
  loaded?: boolean;
  cart: IOrderProductProps[];
}

export default class CartPage extends React.Component<ICartPageProps, ICartPageState> {
  constructor(props: ICartPageProps) {
    super(props);

    this.state = {
      cart: []
    };
  }

  public componentDidMount = async () => {
    const cart = await CartService.retrieve();
    this.setState({loaded: true, cart});
  }

  private getTotalPrice = () => {
    let totalPrice = 0;
    
    this.state.cart.forEach((p) => {
      totalPrice += p.price;
    });

    return totalPrice;
  }

  private renderFooterComponent = () => {
    let leftItems: JSX.Element = <Stack>
      <NavLink to={"/package"}><PrimaryButton iconProps={{ iconName: "Add" }} text={"Add More"} /></NavLink>
    </Stack>

    let items: JSX.Element = <Stack>
      <Text variant='small'>TOTAL</Text>
      <Text variant={"xLarge"}>Rp. {GeneralService.getPriceWithSeparator(this.getTotalPrice() + "")}</Text>
    </Stack>;

    let rightItems: JSX.Element = <Stack horizontal horizontalAlign='end'>
      <NavLink to={"/checkout"}><PrimaryButton text={"Checkout"} /></NavLink>
    </Stack>

    return { leftItems, items, rightItems };
  }

  private _onDeleteCartItem = async (item: IOrderProductProps) => {
    if (window.confirm(`Are you sure to delete product ${item.product.name} from your shopping cart?`)) {
      await CartService.remove(item.id);
      const cart = await CartService.retrieve();
      this.setState({cart});
    }
  }

  public render() {
    const { context } = this.props;
    const { loaded, cart } = this.state;

    return (
      <Stack className={styles.container} tokens={{ childrenGap: 15 }}>
        <Text as={'h2'} variant='xLarge'>SHOPPING CART</Text>
        <CartComponent cart={cart} showDeliveryDetails={false} onDelete={this._onDeleteCartItem} />
        <FooterComponent context={context} steps={'cart'} {...this.renderFooterComponent()} />
      </Stack>
    );
  }
}
