import * as React from 'react';

// import ui
import { DialogType, Icon, Stack, Text } from '@fluentui/react';

// import styles
import styles from './styles.module.scss';
import FooterComponent from '../../components/footer';
import { ProviderStore } from '../../context/global';
import ModalAddDelivery from './surfaces/addDelivery';

interface ISelectDeliveryTypeProps {
  context: ProviderStore;
}

interface ISelectDeliveryTypeState {
  dialogSendAddress?: JSX.Element;
}

export default class SelectDeliveryType extends React.Component<
  ISelectDeliveryTypeProps,
  ISelectDeliveryTypeState
> {
  constructor(props: ISelectDeliveryTypeProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { context } = this.props;
    const { dialogSendAddress } = this.state;
    return (
      <Stack tokens={{ childrenGap: 15 }}>
        {dialogSendAddress && dialogSendAddress}
        <Text className={styles.title}>SELECT DELIVERY TYPE</Text>

        <Stack tokens={{ childrenGap: 10 }}>
          <Stack
            className={styles.item}
            horizontal
            verticalAlign='center'
            tokens={{ childrenGap: 10 }}
            onClick={() =>
              this.setState({
                dialogSendAddress: (
                  <ModalAddDelivery
                    onDismiss={() => {
                      this.setState({ dialogSendAddress: undefined });
                    }}
                  />
                ),
              })
            }
          >
            <Icon className={styles.icon} iconName='HomeIcon' />
            <Stack>
              <Text className={styles.title}>Send to 1 Address</Text>
              <Text className={styles.subTitle}>
                Enter 1 delivery details only that will be applied to all packages.
              </Text>
            </Stack>
          </Stack>
          <Stack className={styles.item} horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
            <Icon className={styles.icon} iconName='PinMap' />
            <Stack>
              <Text className={styles.title}>Send to Multiple Address</Text>
              <Text className={styles.subTitle}>Set different address for each package.</Text>
            </Stack>
          </Stack>
        </Stack>
        <FooterComponent context={context} steps={'selectDeliveryType'} />
      </Stack>
    );
  }
}
