import * as React from 'react';

// import ui
import { Stack, Text } from '@fluentui/react';

// import styles
import styles from './style.module.scss';

// import assets
import package1 from '../../assets/products/loure.png';
import { ProviderStore } from '../../../../context/global';
import FooterComponent from '../../../../components/footer';
import { NavLink } from 'react-router-dom';

import { IAvailableDataProps } from '../../../../props/general';
import { IProductResourceShort } from '../../../../props/products';
import GeneralService from '../../../../services/general';
import { TStep } from '../../index';
import moment from 'moment';
import { IOrderProductProps } from '../../../../props/orders/product';

interface ISelectProductProps {
  data: IAvailableDataProps;
  context: ProviderStore;
  product?: IOrderProductProps;
  onChangeStep(step: TStep, product: IOrderProductProps): void;
}

interface ISelectProductState {}

export default class SelectProduct extends React.Component<ISelectProductProps, ISelectProductState> {
  constructor(props: ISelectProductProps) {
    super(props);

    this.state = {};
  }

  /*private renderProductCard = (product: IProductResourceShort) => {
    return <Stack horizontal horizontalAlign={'space-evenly'} verticalAlign={'center'} className={styles.card} onClick={() => this._onProductSelected(product)}>
      <Stack className={styles.image}>
        <img src={GeneralService.getFileUrl(product.images[0].url)} />
      </Stack>
      <Stack className={styles.details} horizontalAlign={"center"} tokens={{childrenGap: 2}}>
        <Text variant='xLarge'>{product.name}</Text>
        <Text variant='mediumPlus'>Rp. {GeneralService.getPriceWithSeparator(product.price + "")} {product.minQuantity !== product.maxQuantity ? ' / piece' : ''}</Text>
      </Stack>
    </Stack>
  }*/
  private renderProductCard = (product: IProductResourceShort) => {
    return <Stack className={styles.card} onClick={() => this._onProductSelected(product)} tokens={{childrenGap: 5}}>
      <Stack className={styles.image} horizontalAlign={'center'}>
        <img src={GeneralService.getFileUrl(product.images[0]?.url)} />
      </Stack>
      <Stack className={styles.details} tokens={{childrenGap: 2}}>
        <Text variant='medium' style={{fontWeight: '600'}}>{product.name}</Text>
        <Text variant='small'>Rp. {GeneralService.getPriceWithSeparator(product.price + "")} {product.minQuantity !== product.maxQuantity ? ' / piece' : ''}</Text>
      </Stack>
    </Stack>
  }

  private _onProductSelected = (product: IProductResourceShort) => {
    this.props.onChangeStep('selectPackaging', {
      id: moment().toISOString(),
      toppings: [],
      productId: product.id,
      product,
      flavours: product.defaultFlavours.map((df) => {
        return {
          id: moment().toISOString(),
          additionalPrice: df.maxQuantity * (df.flavour.additionalPrice || 0),
          flavour: df.flavour,
          flavourId: df.flavourId,
          orderProductId: product.id,
          quantity: df.maxQuantity
        }
      }),
      packaging: product.packagings[0].packaging,
      packagingId: product.packagings[0].packaging.id,
      price: product.price
    });
  }

  public render() {
    const { data, context } = this.props;
    return (
      <Stack horizontalAlign='center' tokens={{ childrenGap: 15 }}>
        <Text variant='xLarge' className='header'>SELECT YOUR PACKAGE:</Text>
        <Stack grow={1} horizontal horizontalAlign='center' className={styles.container}>
          {data.products.map((product) => this.renderProductCard(product))}
        </Stack>

        {/*<Stack horizontal tokens={{ childrenGap: 15 }} verticalAlign='center' horizontalAlign='center'>
          <NavLink
            style={{ textDecoration: 'none' }}
            children={
              <Stack className={styles.package} verticalAlign='center' horizontalAlign='center'>
                <img src={package1} alt='package1' className={styles.imgPackage} />
                <Text className={styles.textTitle}>Package 1</Text>
                <Text className={styles.textPrice}>(Rp. 100.000)</Text>
              </Stack>
            }
            to={config.url.selectBox.index}
          />
          <NavLink
            style={{ textDecoration: 'none' }}
            children={
              <Stack className={styles.package} verticalAlign='center' horizontalAlign='center'>
                <img src={package1} alt='package1' className={styles.imgPackage} />
                <Text className={styles.textTitle}>Package 2</Text>
                <Text className={styles.textPrice}>(Rp. 100.000)</Text>
              </Stack>
            }
            to={config.url.selectBox.index}
          />
          </Stack>*/}
      </Stack>
    );
  }
}
