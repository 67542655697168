import Request from './../request';
import { IToppingResourceShortProps } from '../../props/toppings';

// import interfaces

const ToppingService = {
  retrieve: async (): Promise<IToppingResourceShortProps[]> => {
    try {
      const results = await Request.get(`/api/toppings`);
      return results;
    } catch (e) {
      throw e;
    }
  }
};

export default ToppingService;
