import * as React from 'react';

// import ui
import { Stack } from '@fluentui/react';
import FooterComponent from '../../components/footer';
import { ProviderStore } from '../../context/global';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import SelectDeliveryDate from './subpages/deliveryDate';
import { IOrderProductProps } from '../../props/orders/product';
import CartService from '../../services/cart';
import SelectDeliveryType from './subpages/deliveryType';
import DeliveryDetails from './subpages/deliveryDetails';
import LoadingDialogSurface from '../../components/loadingDialog';
import moment from 'moment';
import OrderService from '../../services/orders';
import { IAvailableDataProps } from '../../props/general';
import UpdateDeliveryDetailsSurface from './surfaces/deliveryDetails';
import { IDeliveryDetailsResourceShortProps } from '../../props/orders/deliveryDetails';
import ThankyouPage from './subpages/thankyou';
import GeneralService from '../../services/general';

interface ICheckoutPageProps {
  data: IAvailableDataProps;
  context: ProviderStore;
  navigate: NavigateFunction;
}

export type TStep = 'selectDeliveryDate' | 'selectDeliveryType' | 'deliveryDetails';

export interface ICheckoutDataProps {
 deliveryDate: string;
 deliveryType: 'single' | 'multiple';
}

interface ICheckoutPageState {
  step: TStep;
  loaded?: boolean;
  selected: Partial<ICheckoutDataProps>;
  submitting?: boolean;
  submitted?: boolean;
  finalCart: IOrderProductProps[];
  deliveryDetails?: IDeliveryDetailsResourceShortProps;
}
declare var window:any;
class CheckoutComp extends React.Component<ICheckoutPageProps, ICheckoutPageState> {
  constructor(props: ICheckoutPageProps) {
    super(props);

    this.state = {
      step: 'selectDeliveryDate',
      selected: {},
      finalCart: []
    };
  }

  public componentDidMount = async () => {

    const cart = await CartService.retrieve();
    if (cart.length < 1) {
      window.location.href = "/";
    }
  }

  private _onStepChanged = async (step: TStep, selected: Partial<ICheckoutDataProps>) => {
    this.setState({step, selected});
  }

  private _onDeliveryDetailsSubmitted = async (details: IDeliveryDetailsResourceShortProps) => {
    this.setState({deliveryDetails: details}, () => this._onSubmit());
  }

  private _onSubmit = async () => {
    if (window.confirm("Are you sure all details are correct? You will not be able to change the details once submitted.")) {
      try {
        const {selected, deliveryDetails} = this.state;

        this.setState({submitting: true});
        const cart = await CartService.retrieve();
        const totalPrice = this.getTotalPrice(cart);
        
        const data = new FormData();
        data.append('price', totalPrice + "");
        data.append('orderNumber', moment().format("DDMMYY.HHmmss.") + GeneralService.generateRandomText(6).toUpperCase());

        if (deliveryDetails) {
          data.append('deliveryDetails', JSON.stringify(deliveryDetails));
          cart.map((c) => {
            return {...c, deliveryDetails: undefined}
          });
        }

        cart.forEach((c) => {
          data.append('products[]', JSON.stringify(c));
        });

        await OrderService.submit(data);
        await CartService.reset();

        this.setState({submitting: false, submitted: true, finalCart: cart});
      } catch(e) {
        this.setState({submitting: false});
      }
    }
  }

  private getTotalPrice = (cart: IOrderProductProps[]) => cart
    .filter(f => f.price !== undefined)
    .map(c => Number(c.price)).reduce((a,b) => a + b)

  public render() {
    const { context, data } = this.props;
    const { step, selected, submitting, submitted, finalCart, deliveryDetails } = this.state;
    
    return (
      <Stack>
        {!submitted ? <>
          {submitting ? <LoadingDialogSurface title={"Submitting your order ..."} /> : null}
          {step === 'selectDeliveryDate' ? <SelectDeliveryDate data={data} context={context} selected={selected} onChangeStep={this._onStepChanged} /> : null}
          {step === 'selectDeliveryType' || selected.deliveryType === 'single' ? <SelectDeliveryType data={data} context={context} selected={selected} onChangeStep={this._onStepChanged} /> : null}
          {step === 'deliveryDetails' && selected.deliveryDate && selected.deliveryType === 'multiple' ? <DeliveryDetails data={data} onSubmit={this._onSubmit} context={context} selected={selected as ICheckoutDataProps} onChangeStep={this._onStepChanged} /> : null}
          {selected.deliveryType === 'single' && selected.deliveryDate && step === 'deliveryDetails' ? <UpdateDeliveryDetailsSurface data={this.props.data} deliveryDetails={deliveryDetails} deliveryDate={selected.deliveryDate}  onSubmit={this._onDeliveryDetailsSubmitted} onDismiss={() => {
            this.setState({step: 'selectDeliveryType'});
          }} /> : null}
        </> : null}
        {selected.deliveryDate && submitted ? <>
          <ThankyouPage deliveryDetails={deliveryDetails} context={context} deliveryDate={selected.deliveryDate} cart={finalCart} />
        </> : null}
      </Stack>
    );
  }
}

export default function Checkout(props: {data: IAvailableDataProps, context: ProviderStore}) {
  const navigate = useNavigate();
  return <CheckoutComp  {...props} navigate={navigate} />;
}