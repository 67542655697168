import * as React from 'react';

// import ui
import { ActionButton, DatePicker, DetailsList, IColumn, Icon, Panel, PanelType, PrimaryButton, SelectionMode, Stack, Text, TooltipHost, TextField, Checkbox, DefaultButton, ChoiceGroup, IChoiceGroupOptionProps, Dropdown, IChoiceGroupOption, IDropdownOption } from '@fluentui/react';
import FooterComponent from '../../../../components/footer';
import { ProviderStore } from '../../../../context/global';
import { NavigateFunction, NavLink, useNavigate } from 'react-router-dom';
import styles from './style.module.scss';
import moment from 'moment';
import { TStep } from '../..';
import { IOrderProductProps } from '../../../../props/orders/product';
import CartService from '../../../../services/cart';
import { ICheckoutDataProps } from '../../index';
import GeneralService from '../../../../services/general';
import validationService from '../../../../services/validation';
import DeliveryMethods, { IDeliveryMethodProps } from '../../../../manifests/deliveryMethods';
import Times from '../../../../manifests/times';
import { IDeliveryMethodResourceShort } from '../../../../props/data';
import { IAvailableDataProps } from '../../../../props/general';
import { IDeliveryDetailsResourceShortProps } from '../../../../props/orders/deliveryDetails';

interface IUpdateDeliveryDetailsProps {
    data: IAvailableDataProps;
    /*product: IOrderProductProps;
    selected: ICheckoutDataProps;*/

    deliveryDetails?: IDeliveryDetailsResourceShortProps;
    deliveryDate: string;
    onDismiss(): void;
    onSubmit(details: IDeliveryDetailsResourceShortProps): void;
}

interface IUpdateDeliveryDetailsState {
    senderName: string;
    senderPhone: string;
    recipientAddress: string;
    recipientName: string;
    recipientPhone: string;
    recipientSameAsSender: boolean;
    deliveryMethod: string;
    message: string;
    giftCard: boolean;
    deliveryTime: string;
    errorSenderName?: string;
    errorSenderPhone?: string;
    errorRecipientName?: string;
    errorRecipientPhone?: string;
    errorRecipientAddress?: string;
    errorMessage?: string;
    errorDeliveryTime?: string;
    errorDeliveryMethod?: string;
}

declare var window:any;

export default class UpdateDeliveryDetailsSurface extends React.Component<IUpdateDeliveryDetailsProps, IUpdateDeliveryDetailsState> {
    constructor(props: IUpdateDeliveryDetailsProps) {
        super(props);

        this.state = {
            senderName: "",
            senderPhone: "",
            recipientAddress: "",
            recipientName: "",
            recipientPhone: "",
            message: "",
            deliveryMethod: 'pickup',
            recipientSameAsSender: true,
            deliveryTime: '',
            giftCard: false
        };
    }

    private _onSubmit = async () => {
        const { senderName, senderPhone, recipientAddress, recipientName, recipientPhone, giftCard, message, deliveryMethod, deliveryTime } = this.state;
        const method = this.props.data.deliveryMethods.find((dm) => dm.id === deliveryMethod);
        this.props.onSubmit({
            id: moment().toISOString(),
            senderName,
            senderPhone,
            recipientAddress,
            recipientName,
            recipientPhone,
            giftCard,
            message, 
            deliveryMethodId: method?.id || this.props.data.deliveryMethods[0].id,
            deliveryMethod: method || this.props.data.deliveryMethods[0], 
            deliveryTime
        });
    }

    public componentDidMount = async () => {
        const { senderName, senderPhone, recipientAddress, recipientName, recipientPhone, giftCard, message, deliveryMethod, deliveryTime } = this.props.deliveryDetails || {};
        this.setState({
            senderName: senderName || "",
            senderPhone: senderPhone || "",
            recipientAddress: recipientAddress || "",
            recipientName: recipientName || "",
            recipientPhone: recipientPhone || "",
            recipientSameAsSender: recipientName === senderName || recipientPhone === senderPhone,
            deliveryMethod: deliveryMethod ? deliveryMethod.id : "",
            deliveryTime: deliveryTime || "",
            giftCard: giftCard || false,
            message: message || ""
        });
    }

    private _onRecipientSameAsSenderChanged = (ev?: any, checked?: boolean) => {
        this.setState({
            recipientSameAsSender: checked || false,
            recipientName: checked ? this.state.senderName : "",
            recipientPhone: checked ? this.state.senderPhone : "",
            errorRecipientName: undefined,
            errorRecipientPhone: undefined
        });
    }

    private _onDeliveryMethodChanged = (evt?: any, opt?: IChoiceGroupOption) => {
        const deliveryMethod = opt ? opt.key as string : "";
        this.setState({ deliveryMethod, deliveryTime: '' });
    }

    private _onDeliveryTimeChanged = (evt?: any, opt?: IDropdownOption) => {
        const deliveryTime = opt ? opt.key as string : "";
        this.setState({ deliveryTime, errorDeliveryTime: undefined });
    }

    private _onSenderNameChanged = (ev?: any, value?: string) => {
        const { message } = validationService.combination(value, ['required', 'limit'], { maxChars: 125 });
        this.setState({
            senderName: value || "",
            recipientName: this.state.recipientSameAsSender ? value || "" : this.state.recipientName,
            errorSenderName: message
        })
    }

    private _onSenderPhoneChanged = (ev?: any, value?: string) => {
        const { message } = validationService.combination(value, ['required', 'limit', 'phoneNumber'], { maxChars: 20 });
        this.setState({
            senderPhone: value || "",
            recipientPhone: this.state.recipientSameAsSender ? value || "" : this.state.recipientPhone,
            errorSenderPhone: message
        });
    }

    private _onRecipientNameChanged = (ev?: any, value?: string) => {
        const { message } = validationService.combination(value, ['required', 'limit'], { maxChars: 125 });
        this.setState({ recipientName: value || "", errorRecipientName: message })
    }

    private _onRecipientPhoneChanged = (ev?: any, value?: string) => {
        const { message } = validationService.combination(value, ['required', 'limit', 'phoneNumber'], { maxChars: 20 });
        this.setState({ recipientPhone: value || "", errorRecipientPhone: message });
    }

    private _onRecipientAddressChanged = (ev?: any, value?: string) => {
        const { message } = validationService.combination(value, ['required', 'limit'], { maxChars: 125 });
        this.setState({ recipientAddress: value || "", errorRecipientAddress: message })
    }

    private _onGiftCardChanged = (ev?: any, checked?: boolean) => {
        this.setState({ giftCard: checked || false });
    }

    private _onMessageChanged = (ev?: any, value?: string) => {
        const { message } = validationService.combination(value, ['required', 'limit'], { maxChars: 1000 });
        this.setState({ message: value || "", errorMessage: message });
    }

    private isSubmitButtonDisabled = () => {
        const { deliveryMethod, deliveryTime, senderName, senderPhone, recipientAddress, recipientName, recipientPhone, giftCard, message } = this.state;
        const { errorSenderName, errorSenderPhone, errorRecipientAddress, errorRecipientName, errorRecipientPhone, errorMessage } = this.state;

        if (senderName.trim() === "" || senderPhone.trim() === "" || recipientAddress.trim() === "" || recipientName.trim() === "" || recipientPhone.trim() === "") {
            return true;
        } else if (errorSenderName || errorSenderPhone || errorRecipientPhone || errorRecipientName || errorRecipientAddress) {
            return true;
        } else if (giftCard && errorMessage) {
            return true;
        } else if ((deliveryMethod === 'pickup' || deliveryMethod === 'gojek') && deliveryTime.trim() === '') {
            return true;
        }

        return false;
    }

    private renderDeliveryMethodOption = (method: IDeliveryMethodResourceShort) => {
        let isDisabled = false;
        let isToday = moment(this.props.deliveryDate).isSame(moment(), 'day');
        if (method.minDaysToOrder && method.minDaysToOrder > 0) {
            const diff = moment(this.props.deliveryDate).diff(moment().add(method.minDaysToOrder, 'days'), 'days');
            if (diff < 0) {
                isDisabled = true;
            }
        } else if (method.endTime && isToday) {
            let endTime = moment(method.endTime + '', 'H');
            if (method.minHoursToOrder) {
                endTime = endTime.add(-1 * (method.minHoursToOrder-1), 'hours');
            }
            const diff = moment(this.props.deliveryDate).diff(endTime, 'hours');
            if (diff >= 0) {
                isDisabled = true;
            }
        }

        return {
            key: method.id,
            text: "",
            disabled: isDisabled,
            onRenderField: (props?: IChoiceGroupOptionProps, render?: any) => {
                return <Stack horizontal>
                    {render!(props)}
                    <Stack grow={1} tokens={{ childrenGap: 2 }}>
                        <Text style={{ fontWeight: 600 }}>{method.name}</Text>
                        <Text variant={"small"}>{method.description}</Text>
                    </Stack>
                </Stack>;
            }
        }
    }

    public render() {
        const { senderName, senderPhone, deliveryMethod, deliveryTime, recipientAddress, recipientName, recipientPhone, recipientSameAsSender, giftCard, message } = this.state;
        const { errorSenderName, errorSenderPhone, errorRecipientAddress, errorRecipientName, errorRecipientPhone, errorMessage } = this.state;

        let availableTimes:{key: number; text: string;}[] = [];
        window.moment = moment;
        const method = this.props.data.deliveryMethods.find((method) => method.id === deliveryMethod);
        if (method && method.startTime && method.endTime && method.startTime !== method.endTime) {
            for (var time = method.startTime; time <= method.endTime; time++) {
                availableTimes.push({key: time, text: moment(time, 'H').format("H A")});
            }

            if (moment().isSame(moment(this.props.deliveryDate), 'days')) {
                const currentHour = parseInt(moment().format("H"));
                availableTimes = availableTimes.filter((time) => time.key > currentHour);
            }
        }

        return <Panel headerText='Delivery Details'
            type={PanelType.medium}
            isOpen={true}
            onDismiss={this.props.onDismiss}
            onRenderFooterContent={() => {
                return <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <PrimaryButton text={"Save"} disabled={this.isSubmitButtonDisabled()} onClick={this._onSubmit} />
                    <DefaultButton text={"Cancel"} onClick={() => this.props.onDismiss()} />
                </Stack>;
            }}
            isFooterAtBottom={true}>
            <Stack tokens={{ childrenGap: 30 }}>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Text variant='large'>Delivery Method</Text>
                    <ChoiceGroup onChange={this._onDeliveryMethodChanged} className={'blockRadio'} selectedKey={deliveryMethod} options={this.props.data.deliveryMethods.map((method) => this.renderDeliveryMethodOption(method))} />
                    {availableTimes.length > 0 ? <Dropdown label='Pick-up/Delivery time' required options={availableTimes.map((time) => {return {key: time.key + '', text: time.text}})} selectedKey={deliveryTime} onChange={this._onDeliveryTimeChanged} /> : null}
                </Stack>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Text variant='large'>Sender Details</Text>
                    <TextField label={"Sender's name"}
                        required
                        value={senderName}
                        errorMessage={errorSenderName}
                        onChange={this._onSenderNameChanged} />
                    <TextField label={"Sender's phone number"}
                        required
                        value={senderPhone}
                        errorMessage={errorSenderPhone}
                        onChange={this._onSenderPhoneChanged} />
                </Stack>
                <Stack tokens={{ childrenGap: 10 }}>
                    <Text variant='large'>Recipient Details</Text>
                    <Checkbox label='Recipient same as sender'
                        styles={{ root: { paddingTop: 5 } }}
                        checked={recipientSameAsSender}
                        onChange={this._onRecipientSameAsSenderChanged} />
                    <TextField label={"Recipient's name"}
                        value={recipientName}
                        required
                        errorMessage={errorRecipientName}
                        disabled={recipientSameAsSender}
                        onChange={this._onRecipientNameChanged} />
                    <TextField label={"Recipient's phone number"}
                        value={recipientPhone}
                        required
                        disabled={recipientSameAsSender}
                        errorMessage={errorRecipientPhone}
                        onChange={this._onRecipientPhoneChanged} />
                    <TextField label={"Delivery address"}
                        required
                        value={recipientAddress}
                        multiline
                        autoAdjustHeight
                        resizable={false}
                        rows={5}
                        errorMessage={errorRecipientAddress}
                        onChange={this._onRecipientAddressChanged} />
                    <Checkbox label='Complimentary gift card'
                        styles={{ root: { paddingTop: 5 } }}
                        checked={giftCard}
                        onChange={this._onGiftCardChanged} />
                    {
                        giftCard ? <TextField label={"Message"}
                            value={message}
                            multiline
                            autoAdjustHeight
                            resizable={false}
                            rows={5}
                            errorMessage={errorMessage}
                            onChange={this._onMessageChanged} /> : null
                    }
                </Stack>
            </Stack>
        </Panel>;
    }
}