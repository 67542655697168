import * as React from 'react';

// import ui
import { ActionButton, DatePicker, DetailsList, IColumn, Icon, PrimaryButton, SelectionMode, Stack, Text, TooltipHost, DefaultButton } from '@fluentui/react';
import FooterComponent from '../../../../components/footer';
import { ProviderStore } from '../../../../context/global';
import { NavigateFunction, NavLink, useNavigate } from 'react-router-dom';
import styles from './style.module.scss';
import moment from 'moment';
import { TStep } from '../..';
import { IOrderProductProps } from '../../../../props/orders/product';
import CartService from '../../../../services/cart';
import { ICheckoutDataProps } from '../../index';
import GeneralService from '../../../../services/general';
import UpdateDeliveryDetailsSurface from '../../surfaces/deliveryDetails';
import CartComponent from '../../../../components/cart';
import { IAvailableDataProps } from '../../../../props/general';
import { IDeliveryDetailsResourceShortProps } from '../../../../props/orders/deliveryDetails';

interface IDeliveryDetailsProps {
  data: IAvailableDataProps;
  context: ProviderStore;
  selected: ICheckoutDataProps;
  onSubmit(): void;
  onChangeStep(step: TStep, selected: ICheckoutDataProps): void;
}

interface IDeliveryDetailsState {
  loaded?: boolean;
  cart: IOrderProductProps[];
  selectedProduct?: IOrderProductProps;
  showUpdateDeliveryDetailsSurface?: boolean;
}

export default class SelectDeliveryType extends React.Component<IDeliveryDetailsProps, IDeliveryDetailsState> {
  constructor(props: IDeliveryDetailsProps) {
    super(props);

    this.state = {
      cart: [],
      loaded: true
    };
  }

  public componentDidMount = async () => {
    const cart = await CartService.retrieve();
    this.setState({ cart });
  }

  private renderFooterComponent = () => {
    let leftItems: JSX.Element = <Stack>
      <ActionButton iconProps={{ iconName: "Back" }} text={"Back"} onClick={() => this.props.onChangeStep('selectDeliveryType', { ...this.props.selected })} />
    </Stack>

    let isSubmitButtonDisabled = false;
    this.state.cart.forEach((item) => {
      if (!item.deliveryDetails) {
        isSubmitButtonDisabled = true;
      }
    })

    let rightItems: JSX.Element = <Stack>
      <PrimaryButton text={"Submit"} disabled={isSubmitButtonDisabled} onClick={this.props.onSubmit} />
    </Stack>;

    return { leftItems, rightItems };
  }

  private _onSurfaceDismissed = async () => {
    this.setState({loaded: false});
    const cart = await CartService.retrieve();
    this.setState({ showUpdateDeliveryDetailsSurface: false, cart, loaded: true });
  }

  private _onAddDeliveryDetailsClicked = (item: IOrderProductProps) => {
    this.setState({ selectedProduct: item, showUpdateDeliveryDetailsSurface: true })
  }

  /*private renderDetailsCard = (product: IOrderProductProps) => {
    return <Stack horizontal className={styles.card} tokens={{ childrenGap: 20 }}>
      <Stack.Item className={styles.image}>
        <img src={GeneralService.getFileUrl(product.product.images[0].url)} />
      </Stack.Item>
      <Stack horizontalAlign={"baseline"} tokens={{ childrenGap: 10 }}>
        <Stack className={styles.details} horizontalAlign={"baseline"} tokens={{ childrenGap: 2 }}>
          <Text variant='xLarge'>{product.product.name}</Text>
          <Text variant='small'><Text variant='small' style={{ fontWeight: 600 }}>Packaging: </Text>{product.packaging.name}</Text>
          <Text variant='small'><Text variant='small' style={{ fontWeight: 600 }}>Flavours: </Text>{product.flavours.map((fl) => `${fl.flavour.name} (x${fl.quantity})`).join(", ")}</Text>
        </Stack>
        <Stack tokens={{ childrenGap: 2 }}>
          {product.recipientName.trim() === "" ? <Stack tokens={{childrenGap: 10}}>
            <Text variant='small' style={{ fontWeight: 600 }}>Delivery Details</Text>
            <PrimaryButton iconProps={{ iconName: "Add" }} text={"Add Delivery Details"} className={styles.detailsButton} onClick={() => this.setState({ selectedProduct: product, showUpdateDeliveryDetailsSurface: true })} />
          </Stack> : null}
          {product.recipientName.trim() !== "" ? <Stack>
            <table>
              <tr>
                <td valign='top'><Text variant='small' style={{ fontWeight: 600 }}>Sender</Text></td>
                <td valign='top'><Text variant='small' style={{ fontWeight: 600 }}>:</Text></td>
                <td valign='top'><Text variant='small'>{product.senderName} - {product.senderPhone}</Text></td>
              </tr>
              <tr>
                <td valign='top'><Text variant='small' style={{ fontWeight: 600 }}>Recipient</Text></td>
                <td valign='top'><Text variant='small' style={{ fontWeight: 600 }}>:</Text></td>
                <td valign='top'><Text variant='small'>{product.recipientName} - {product.recipientPhone}</Text></td>
              </tr>
              <tr>
                <td valign='top'><Text variant='small' style={{ fontWeight: 600 }}>Address</Text></td>
                <td valign='top'><Text variant='small' style={{ fontWeight: 600 }}>:</Text></td>
                <td valign='top'><Text variant='small'>{product.recipientAddress}</Text></td>
              </tr>
              <tr>
                <td valign='top'><Text variant='small' style={{ fontWeight: 600 }}>Gift Card</Text></td>
                <td valign='top'><Text variant='small' style={{ fontWeight: 600 }}>:</Text></td>
                <td valign='top'><Text variant='small'>{product.giftCard ? "Yes" : "No"}</Text></td>
              </tr>
              {
                product.giftCard ? <tr>
                  <td valign='top'><Text variant='small' style={{ fontWeight: 600 }}>Message</Text></td>
                  <td valign='top'><Text variant='small' style={{ fontWeight: 600 }}>:</Text></td>
                  <td valign='top'><Text variant='small'>{product.message}</Text></td>
                </tr> : null
              }
            </table>
            <Stack.Item>
              <DefaultButton iconProps={{ iconName: "Edit" }} text={"Update Details"} className={styles.detailsButton} onClick={() => this.setState({ selectedProduct: product, showUpdateDeliveryDetailsSurface: true })} />
              </Stack.Item>
          </Stack> : null}
        </Stack>
      </Stack>
    </Stack>
  }*/

  private _onDeliveryDetailsSubmitted = async (details: IDeliveryDetailsResourceShortProps) => {
    let product = this.state.selectedProduct;
    if (product) {
      product.deliveryDetails = details;
      await CartService.update(product.id, product);
      this.setState({selectedProduct: undefined, showUpdateDeliveryDetailsSurface: false});
    }
  }

  public render() {
    const { context, selected } = this.props;
    const { cart, loaded, selectedProduct, showUpdateDeliveryDetailsSurface } = this.state;

    return (
      <Stack className={styles.deliveryDetails} tokens={{ childrenGap: 15 }}>
        <Text variant='xLarge' className='header'>DETAILS - (Delivery or Pick-up on {moment(selected.deliveryDate).format("DD/MM/YYYY")})</Text>
        {/*<Stack styles={{ root: { width: '100%' } }}>
          {selected.deliveryType === 'multiple' ? <Stack tokens={{ childrenGap: 20 }}>
            {cart.map((p) => this.renderDetailsCard(p))}
          </Stack> : null}
    </Stack>*/}
    
        {loaded ? <CartComponent cart={cart} showDeliveryDetails={true} onAddDeliveryDetails={this._onAddDeliveryDetailsClicked} onUpdateDeliveryDetails={this._onAddDeliveryDetailsClicked} /> : null}
        <FooterComponent context={context} steps={'selectPackage'} {...this.renderFooterComponent()} />
        {showUpdateDeliveryDetailsSurface && selectedProduct ? <UpdateDeliveryDetailsSurface data={this.props.data} deliveryDate={selected.deliveryDate} deliveryDetails={selectedProduct.deliveryDetails} onSubmit={this._onDeliveryDetailsSubmitted} onDismiss={this._onSurfaceDismissed} /> : null}
      </Stack>
    );
  }
}