import * as React from 'react';

// import ui
import { CommandButton, PrimaryButton, Stack } from '@fluentui/react';

// import styles
import styles from './styles.module.scss';
import { ProviderStore } from '../../context/global';

import config from '../../utils/config.json';
import { NavLink } from 'react-router-dom';
import { StepsType } from '../../props/data';

interface IFooterComponentProps {
  context: ProviderStore;
  leftItems?: JSX.Element;
  items?: JSX.Element;
  rightItems?: JSX.Element;
  steps: StepsType;
}

interface IFooterComponentState {}

export default class FooterComponent extends React.Component<IFooterComponentProps, IFooterComponentState> {
  constructor(props: IFooterComponentProps) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <Stack className={`${styles.container} lou-of-container`}>
        <Stack className='lou-of-content' styles={{root: {height: '100%'}}} horizontal horizontalAlign='space-between' verticalAlign='center'>
          {this.props.leftItems ? this.props.leftItems : null}
          {this.props.items ? this.props.items : null}
          {this.props.rightItems ? this.props.rightItems : null}
        </Stack>
      </Stack>
    );
  };
}
