import Cookie from 'js-cookie';
import { IOrderProductProps } from '../../props/orders/product';

const CartService = {
    retrieve: async ():Promise<IOrderProductProps[]> => {
        const items = window.localStorage.getItem('LOURE_CART');
        return JSON.parse(items || '[]');
    },
    add: async (product: IOrderProductProps) => {
        const res = window.localStorage.getItem('LOURE_CART');
        const items: IOrderProductProps[] = res ? JSON.parse(res) : [];
        items.push(product);
        window.localStorage.setItem('LOURE_CART', JSON.stringify(items));

        return product;
    },
    update: async (id: string, product: IOrderProductProps) => {
        const res = window.localStorage.getItem('LOURE_CART');
        let items: IOrderProductProps[] = res ? JSON.parse(res) : [];
        const idx = items.findIndex((p) => p.id === id);
        if (idx > -1 && items[idx]) {
            items[idx] = product;
        }
        window.localStorage.setItem('LOURE_CART', JSON.stringify(items));
    },
    remove: async (id: string) => {
        const res = window.localStorage.getItem('LOURE_CART');
        let items: IOrderProductProps[] = res ? JSON.parse(res) : [];
        items = items.filter((item) => item.id.toLowerCase() !== id.toLowerCase());
        window.localStorage.setItem('LOURE_CART', JSON.stringify(items));
    },
    reset: async () => {
        window.localStorage.removeItem('LOURE_CART');
    }
};

export default CartService;