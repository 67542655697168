import * as React from 'react';

// import ui
import {
  ActionButton,
  DefaultButton,
  DetailsList,
  IColumn,
  Icon,
  IconButton,
  Label,
  SelectionMode,
  ShimmeredDetailsList,
  Stack,
  Text,
  TooltipDelay,
  TooltipHost,
} from '@fluentui/react';
import { ProviderStore } from '../../context/global';

// import styles
import styles from './styles.module.scss';
import FooterComponent from '../../components/footer';

import img from '../../assets/products/loure1.png';
import { NavLink } from 'react-router-dom';
import { PrimaryButton } from '@fluentui/react';
import { IOrderProductProps } from '../../props/orders/product';
import CartService from '../../services/cart';
import GeneralService from '../../services/general';
import DeliveryMethods from '../../manifests/deliveryMethods';
import moment from 'moment';

interface ICartComponentProps {
  cart: IOrderProductProps[];
  showDeliveryDetails?: boolean;
  onAddDeliveryDetails?(item: IOrderProductProps): void;
  onUpdateDeliveryDetails?(item: IOrderProductProps): void;
  onDelete?(item: IOrderProductProps): void;
}

interface ICartPageState {
}

export default class CartComponent extends React.Component<ICartComponentProps, {}> {
  private renderDeliveryDetails = (item: IOrderProductProps) => {
    const { onAddDeliveryDetails, onUpdateDeliveryDetails } = this.props;
    return <Stack styles={{ root: { paddingTop: 10 } }}>
      {item.deliveryDetails ? <Stack tokens={{ childrenGap: 5 }} styles={{ root: { borderTop: '1px solid #ddd', paddingTop: 10 } }}>
        <Text style={{ fontWeight: 600 }}>Delivery Details</Text>
        <Stack horizontal tokens={{ childrenGap: 3 }}>
          <Text variant='small' style={{ fontWeight: 600 }}>Method:</Text>
          <Text variant='small'>{item.deliveryDetails.deliveryMethod.name}{item.deliveryDetails.deliveryTime ? ' - ' + moment(item.deliveryDetails.deliveryTime, 'HH:mm').format("HH A") : ''}</Text>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 3 }}>
          <Text variant='small' style={{ fontWeight: 600 }}>Sender:</Text>
          <Text variant='small'>{item.deliveryDetails.senderName} - {item.deliveryDetails.senderPhone}</Text>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 3 }}>
          <Text variant='small' style={{ fontWeight: 600 }}>Recipient:</Text>
          <Text variant='small'>{item.deliveryDetails.recipientName} - {item.deliveryDetails.recipientPhone}</Text>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 3 }}>
          <Text variant='small' style={{ fontWeight: 600 }}>Address:</Text>
          <Text variant='small'>{item.deliveryDetails.recipientAddress}</Text>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 3 }}>
          <Text variant='small' style={{ fontWeight: 600 }}>Gift card?</Text>
          <Text variant='small'>{item.deliveryDetails.giftCard ? 'Yes' : 'No'}</Text>
        </Stack>
        {item.deliveryDetails.message ? <Stack horizontal tokens={{ childrenGap: 3 }}>
          <Text variant='small' style={{ fontWeight: 600 }}>Message:</Text>
          <Text variant='small'>{item.deliveryDetails.message}</Text>
        </Stack> : null}
        {onUpdateDeliveryDetails ? <Stack.Item>
          <PrimaryButton styles={{ root: { minHeight: '30px !important', height: 30 } }} onClick={() => onUpdateDeliveryDetails(item)}>
            <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'}>
              <Icon iconName='Edit' style={{ fontSize: 10, fontWeight: 600, marginTop: 1 }} />
              <Text variant='small' style={{ color: '#fff', fontWeight: 600 }}>Update Delivery Details</Text>
            </Stack>
          </PrimaryButton>
        </Stack.Item> : null}
      </Stack> : null}
      {!item.deliveryDetails && onAddDeliveryDetails ? <Stack.Item>
        <PrimaryButton styles={{ root: { minHeight: '30px !important', height: 30 } }} onClick={() => onAddDeliveryDetails(item)}>
          <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'}>
            <Icon iconName='Add' style={{ fontSize: 10, fontWeight: 600, marginTop: 1 }} />
            <Text variant='small' style={{ color: '#fff', fontWeight: 600 }}>Add Delivery Details</Text>
          </Stack>
        </PrimaryButton>
      </Stack.Item> : null}
    </Stack>
  }

  private renderDetails = (item: IOrderProductProps) => {
    const { onDelete, showDeliveryDetails } = this.props;
    return <Stack grow={1} tokens={{childrenGap: 20}}>
      <Stack grow={1} horizontal className={styles.details} verticalAlign={'center'} tokens={{ childrenGap: 20 }}>
        <img alt={item.product.name} src={GeneralService.getFileUrl(item.product.images[0]?.url)} className={styles.productImage} />
        <Stack grow={1} tokens={{childrenGap: 5}}>
          <Text variant={'xxLarge'} style={{ fontWeight: 600 }}>{item.product.name}</Text>
          {this.renderPrice(item)}
        </Stack>
      </Stack>
      <Stack tokens={{ childrenGap: 3 }}>
        <Stack horizontal tokens={{ childrenGap: 3 }}>
          <Text style={{ fontWeight: 600 }}>Packaging:</Text>
          <Text>{item.packaging.name}</Text>
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 3 }}>
          <Text style={{ fontWeight: 600 }}>Flavours:</Text>
          <Text>{item.flavours.map((fl) => `${fl.flavour.name} (x${fl.quantity})`).join(', ')}</Text>
        </Stack>
        {item.toppings.length > 0 ? <Stack horizontal tokens={{ childrenGap: 3 }}>
          <Text style={{ fontWeight: 600 }}>Toppings:</Text>
          <Text>{item.toppings.map((tp) => `${tp.topping.name} (x${tp.quantity})`).join(', ')}</Text>
        </Stack> : null}
        {showDeliveryDetails ? this.renderDeliveryDetails(item) : null}
        {onDelete ? <Stack.Item styles={{ root: { paddingTop: 10 } }}>
          <DefaultButton styles={{ root: { minHeight: '30px !important', height: 30 } }} onClick={() => onDelete(item)}>
            <Stack horizontal tokens={{ childrenGap: 5 }} verticalAlign={'center'}>
              <Icon iconName='Delete' style={{ fontSize: 10, fontWeight: 600, marginTop: 1 }} />
              <Text variant='small' style={{ fontWeight: 600 }}>Delete</Text>
            </Stack>
          </DefaultButton>
        </Stack.Item> : null}
      </Stack>
    </Stack>;
  }

  private renderPrice = (item: IOrderProductProps) => {
    return <Stack className={styles.price} horizontal grow={1} tokens={{ childrenGap: 8 }}>
      <Text variant={'large'} style={{ fontWeight: 600, marginTop: 1 }}>Rp. {GeneralService.getPriceWithSeparator(item.price + "")}</Text>
      <TooltipHost
        className={styles.priceDetails}
        tooltipProps={{
          onRenderContent: () => {
            const pricing: { name: string; price: string; }[] = [];
            // add product basic price
            pricing.push({
              name: item.product.name,
              price: `Rp. ${GeneralService.getPriceWithSeparator(item.product.price + "")} ${item.product.minQuantity !== item.product.maxQuantity ? ' / piece' : ''}`
            });

            // add packaging additional price
            if (item.packaging.additionalPrice && item.packaging.additionalPrice > 0) {
              pricing.push({
                name: `${item.packaging.name}`,
                price: `+Rp. ${GeneralService.getPriceWithSeparator(item.packaging.additionalPrice + "")}`
              });
            }

            // add flavours additional price
            item.flavours.map((flavour) => {
              if (flavour.additionalPrice && flavour.additionalPrice > 0) {
                pricing.push({
                  name: `${flavour.flavour.name} (x${flavour.quantity})`,
                  price: `+Rp. ${GeneralService.getPriceWithSeparator(flavour.additionalPrice + "")}`
                });
              }
            });

            // toppings additional price
            item.toppings.map((tp) => {
              if (tp.subtotal && tp.subtotal > 0) {
                pricing.push({
                  name: `${tp.topping.name} (x${tp.quantity})`,
                  price: `+Rp. ${GeneralService.getPriceWithSeparator(tp.subtotal + "")}`
                });
              }
            });

            return <Stack tokens={{ childrenGap: 5 }}>
              {pricing.map((data) => {
                return <Stack horizontal horizontalAlign={'space-between'} tokens={{ childrenGap: 20 }}>
                  <Stack.Item grow={1}>
                    <Text variant={'small'} style={{ fontWeight: 600 }}>{data.name}</Text>
                  </Stack.Item>
                  <Stack horizontalAlign='end' styles={{ root: { minWidth: 100, maxWidth: 100 } }}>
                    <Text variant={'small'}>{data.price}</Text>
                  </Stack>
                </Stack>;
              })}
            </Stack>;
          },
        }}
      >
        <Text variant={"large"}><Icon iconName='Info' className={styles.iconInfo} /></Text>
      </TooltipHost>
    </Stack>;
  }

  public render() {
    const { cart } = this.props;

    return <Stack className={styles.cart} tokens={{ childrenGap: 20 }}>
      {cart.map((item, idx) => {
        return <Stack horizontal className={styles.item}>
          <Stack.Item className={styles.index}><Text style={{ fontWeight: 600 }}>{idx + 1}</Text></Stack.Item>
          {this.renderDetails(item)}
        </Stack>
      })}
    </Stack>;
  }
}
