import * as React from 'react';

// import ui
import { Checkbox, Dropdown, DropdownMenuItemType, Stack, TextField } from '@fluentui/react';
import validationService from '../../../services/validation';

interface IDeliveryMethodProps {
  dataKey: string;
}

type DataDeliveryMethodType = {
  pickUpTime?: string;
  sender?: string;
  senderPhoneNumber?: string;
  receiver?: string;
  receiverPhoneNumber?: string;
  address?: string;
};

type DataDeliveryMethodErrorType = {
  errorSender?: string;
  errorSenderPhoneNumber?: string;
  errorReceiver?: string;
  errorReceiverPhoneNumber?: string;
  errorAddress?: string;
};

interface IDeliveryMethodState {
  same: boolean;
  dataKey: string;
  data?: DataDeliveryMethodType;
  errorData?: DataDeliveryMethodErrorType;
}

export default class DeliveryMethod extends React.Component<IDeliveryMethodProps, IDeliveryMethodState> {
  constructor(props: IDeliveryMethodProps) {
    super(props);

    this.state = {
      same: true,
      dataKey: props.dataKey,
    };
  }

  static getDerivedStateFromProps(nextProps: IDeliveryMethodProps, prevState: IDeliveryMethodState) {
    if (nextProps.dataKey !== prevState.dataKey) {
      return { dataKey: nextProps.dataKey };
    }
  }

  public render() {
    const { same, data, errorData } = this.state;
    const { dataKey } = this.state;
    return (
      <Stack>
        {dataKey === 'a' && <Dropdown label='Pick Up Time' options={[{ key: '7pm', text: '7 PM' }]} />}
        {dataKey === 'c' && <Dropdown label='Delivery Time' options={[{ key: '7pm', text: '7 PM' }]} />}
        <TextField
          value={data?.sender}
          label='Sender'
          required
          onChange={this.onChangeSender}
          errorMessage={errorData?.errorSender}
        />
        <TextField
          value={data?.senderPhoneNumber}
          label="Sender's Phone Number"
          required
          onChange={this.onChangeSenderPhoneNumber}
          errorMessage={errorData?.errorSenderPhoneNumber}
        />
        <Checkbox
          checked={same}
          styles={{ root: { paddingTop: 10, paddingBottom: 10 } }}
          label='Receiver same as sender'
          onChange={(e, c) => this.setState({ same: c ?? false })}
        />
        <TextField
          value={same ? data?.sender : data?.receiver}
          label='Receiver'
          required
          disabled={same}
          onChange={this.onChangeReceiver}
          errorMessage={!same ? errorData?.errorReceiver : undefined}
        />
        <TextField
          value={same ? data?.senderPhoneNumber : data?.receiverPhoneNumber}
          label="Receiver's Phone Number"
          required
          disabled={same}
          onChange={this.onChangeReceiverPhoneNumber}
          errorMessage={!same ? errorData?.errorReceiverPhoneNumber : undefined}
        />
        {dataKey !== 'a' && (
          <TextField
            value={data?.address}
            label='Address'
            required
            onChange={this.onChangeReceiverPhoneNumber}
            errorMessage={errorData?.errorAddress}
          />
        )}
      </Stack>
    );
  }

  private onChangeSender = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string | undefined
  ) => {
    const { data, errorData } = this.state;
    const err = validationService.combination(value, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({ data: { ...data, sender: value }, errorData: { ...errorData, errorSender: err } });
  };

  private onChangeSenderPhoneNumber = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string | undefined
  ) => {
    const { data, errorData } = this.state;
    const err = validationService.combination(value, ['phoneNumber', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...data, senderPhoneNumber: value },
      errorData: { ...errorData, errorSenderPhoneNumber: err },
    });
  };
  private onChangeReceiver = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string | undefined
  ) => {
    const { data, errorData } = this.state;
    const err = validationService.combination(value, ['required', 'limit'], { maxChars: 255 }).message;
    this.setState({ data: { ...data, receiver: value }, errorData: { ...errorData, errorReceiver: err } });
  };

  private onChangeReceiverPhoneNumber = (
    event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    value?: string | undefined
  ) => {
    const { data, errorData } = this.state;
    const err = validationService.combination(value, ['phoneNumber', 'limit'], { maxChars: 255 }).message;
    this.setState({
      data: { ...data, receiverPhoneNumber: value },
      errorData: { ...errorData, errorReceiverPhoneNumber: err },
    });
  };
}
