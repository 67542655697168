import { Icon, IconButton, SearchBox, Separator, Stack, Text } from '@fluentui/react';
import * as React from 'react';
//
import styles from './styles.module.scss';

// import assets
import product from '../../assets/products/img.jpg';
import { ProviderStore } from '../../context/global';
import { ItemType } from '../../props/data';
import FooterComponent from '../../components/footer';

interface IOrderProps {
  context: ProviderStore;
}

interface IOrderState {
  items: ItemType[];
}

const items: ItemType[] = [
  {
    name: 'Item 1',
    quantity: 0,
    price: 50000,
    id: 'jkhk',
    stock: 10,
  },
];

export default class Order extends React.Component<IOrderProps, IOrderState> {
  constructor(props: IOrderProps) {
    super(props);

    this.state = {
      items,
    };
  }

  public render() {
    const { context } = this.props;
    return (
      <Stack tokens={{ childrenGap: 20 }}>
        <Text variant='xLarge'>SELECT ITEMS</Text>
        <SearchBox placeholder='Search' onSearch={(newValue) => console.log('value is ' + newValue)} />
        <Stack>
          {this.state.items.map((i) => (
            <Stack key={i.id}>
              <Stack horizontal horizontalAlign='space-between'>
                <Stack tokens={{ childrenGap: 10 }} horizontal verticalAlign='center'>
                  <Stack className={styles.containerImage}>
                    <img src={product} className={styles.img} />
                    <Stack className={styles.startContainer}>
                      <Icon iconName='StarIcon' className={styles.star} color='red' />
                    </Stack>
                  </Stack>
                  <Text styles={{ root: { fontWeight: 500 } }}>{i.name}</Text>
                </Stack>

                <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 20 }}>
                  <IconButton
                    disabled={i.quantity <= 0}
                    iconProps={{ iconName: 'MinusCircle' }}
                    onClick={() => this.onMinus(i)}
                  />
                  <Text>{i.quantity}</Text>
                  <IconButton
                    disabled={i.quantity >= i.stock}
                    iconProps={{ iconName: 'PlusCircle' }}
                    onClick={() => this.onPlus(i)}
                  />
                </Stack>
              </Stack>
              <Separator />
            </Stack>
          ))}
        </Stack>
        <FooterComponent context={context} steps={'orders'} />
      </Stack>
    );
  }

  private onPlus = (i: ItemType) => {
    const rs = [...this.state.items];
    const num = i.quantity + 1;
    rs[rs.findIndex((c) => c.id === i.id)].quantity = num;
    this.setState({ items: rs }, this.setItems);
  };

  private onMinus = (i: ItemType) => {
    const rs = [...this.state.items];
    const num = i.quantity - 1;
    if (num >= 0) {
      rs[rs.findIndex((c) => c.id === i.id)].quantity = num;
      this.setState({ items: rs }, this.setItems);
    }
  };

  private setItems = () => {
    const { context } = this.props;
    context.updateGlobalState({ key: 'items', value: this.state.items.filter((f) => f.quantity > 0) });
  };
}
