import * as React from 'react';

// import ui
import {
  Dialog,
  DialogType,
  Label,
  ChoiceGroup,
  IChoiceGroupOption,
  Stack,
  Text,
  IChoiceGroupOptionProps,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
} from '@fluentui/react';

// import styles
import styles from './styles.module.scss';
import DeliveryMethod from '../component/deliveryMethod';

interface IModalUpdateDeliveryProps {
  onDismiss: () => void;
}

interface IModalUpdateDeliveryState {
  key: string;
}

const choiceGroupStyle = { root: { backgroundColor: '#c7d3eb', padding: 5, borderRadius: 5 } };

export default class ModalUpdateDelivery extends React.Component<
  IModalUpdateDeliveryProps,
  IModalUpdateDeliveryState
> {
  constructor(props: IModalUpdateDeliveryProps) {
    super(props);

    this.state = {
      key: 'a',
    };
  }

  private options: IChoiceGroupOption[] = [
    {
      key: 'a',
      text: 'Pick Up from our kitchen (Sutorejo Prima, East Surabaya).',
      title: 'Pick Up',
      styles: choiceGroupStyle,
      onRenderLabel: (props) => this.onRenderLabel(props),
    },
    {
      key: 'b',
      text: 'Order min. 1 day prior. Delivery window between 9am-4pm.',
      title: 'Delivery By Courier (Rate Rp. 15.000 - Rp. 20.000)',
      styles: choiceGroupStyle,
      onRenderLabel: (props) => this.onRenderLabel(props),
    },
    {
      key: 'c',
      text: 'Same day delivery available between 10am - 7pm.',
      title: 'Delivery By Grab.Gojek (Rate By apps)',
      styles: choiceGroupStyle,
      onRenderLabel: (props) => this.onRenderLabel(props),
    },
  ];

  private onRenderLabel = (props: IChoiceGroupOptionProps | undefined) => (
    <Stack styles={{ root: { marginLeft: 30 } }}>
      <Text styles={{ root: { fontWeight: 500 } }}>{props?.title}</Text>
      <Text>{props?.text}</Text>
    </Stack>
  );

  public render() {
    const { key } = this.state;
    return (
      <Dialog
        hidden={false}
        dialogContentProps={dialogContentProps}
        styles={{ main: { maxWidth: '720px !important', width: '100% !important' } }}
        onDismiss={() => this.props.onDismiss()}
      >
        <ChoiceGroup
          onChange={(ev, op) => {
            if (op?.key) {
              this.setState({ key: op?.key });
            }
          }}
          defaultSelectedKey='a'
          options={this.options}
          label='Delivery Method'
          required={true}
          selectedKey={key}
        />
        <DeliveryMethod dataKey={key} />
        <DialogFooter>
          <DefaultButton
            text='Cancel'
            onClick={() => this.props.onDismiss()}
            styles={{ root: { padding: 20, borderRadius: 5 } }}
          />
          <PrimaryButton text='Submit' styles={{ root: { padding: 20, borderRadius: 5 } }} />
        </DialogFooter>
      </Dialog>
    );
  }
}

const dialogContentProps = {
  type: DialogType.largeHeader,
  closeButtonAriaLabel: 'Close',
  title: 'Add Delivery Details',
};
