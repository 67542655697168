import * as React from 'react';

// import ui
import { ActionButton, Stack, Text } from '@fluentui/react';

// import styles
import styles from './style.module.scss';

// import assets
import package1 from '../../assets/products/loure.png';
import { ProviderStore } from '../../../../context/global';
import FooterComponent from '../../../../components/footer';
import { NavLink } from 'react-router-dom';

import { IAvailableDataProps } from '../../../../props/general';
import { IProductResourceShort } from '../../../../props/products';
import GeneralService from '../../../../services/general';
import { TStep } from '../../index';
import { IPackagingResourceShort } from '../../../../props/packagings';
import { IOrderProductProps } from '../../../../props/orders/product';

interface ISelectPackagingProps {
    data: IAvailableDataProps;
    context: ProviderStore;
    product: IOrderProductProps;
    onChangeStep(step: TStep, product: IOrderProductProps): void;
}

interface ISelectPackagingState { }

export default class SelectPackaging extends React.Component<ISelectPackagingProps, ISelectPackagingState> {
    constructor(props: ISelectPackagingProps) {
        super(props);

        this.state = {};
    }

    private renderPackagingCard = (packaging: IPackagingResourceShort) => {
      return <Stack className={styles.card} tokens={{childrenGap: 5}} onClick={() => this._onPackagingSelected(packaging)}>
        <Stack className={styles.image} horizontalAlign={'center'}>
            <img src={GeneralService.getFileUrl(packaging.images? packaging.images[0].url : '')} />
        </Stack>
        <Stack className={styles.details} tokens={{childrenGap: 2}}>
          <Text variant='medium' style={{fontWeight: '600'}}>{packaging.name}</Text>
          <Text variant='small'>{packaging.additionalPrice > 0 ? `Rp. ${GeneralService.getPriceWithSeparator(packaging.additionalPrice + "")}` : 'FREE'}</Text>
        </Stack>
      </Stack>
    }

    /*private renderPackagingCard = (packaging: IPackagingResourceShort) => {
        return <Stack horizontal horizontalAlign={'space-evenly'} verticalAlign={'center'} className={styles.card} onClick={() => this._onPackagingSelected(packaging)}>
            <Stack className={styles.image}>
                <img src={GeneralService.getFileUrl(packaging.images[0].url)} />
            </Stack>
            <Stack className={styles.details} horizontalAlign={"center"} tokens={{ childrenGap: 2 }}>
                <Text variant='xLarge'>{packaging.name}</Text>
                <Text variant='mediumPlus'>{packaging.additionalPrice > 0 ? `Rp. ${GeneralService.getPriceWithSeparator(packaging.additionalPrice + "")}` : 'FREE'}</Text>
            </Stack>
        </Stack>
    }*/

    private _onPackagingSelected = (packaging: IPackagingResourceShort) => {
        this.props.onChangeStep('selectFlavour', { ...this.props.product, packaging });
    }

    private _onBack = () => {
        this.props.onChangeStep('selectProduct', { ...this.props.product });
    }

    private renderFooterComponent = () => {
        let leftItems: JSX.Element = <Stack>
            <ActionButton iconProps={{iconName: "Back"}} text={"Back"} onClick={this._onBack} />
        </Stack>

        return {leftItems};
    }

    public render() {
        const { data, context, product } = this.props;
        if (product) {
            return (
                <Stack horizontalAlign='center' tokens={{ childrenGap: 15 }}>
                    <Text variant='xLarge' className='header'>SELECT YOUR BOX:</Text>
                    <Stack grow={1} horizontal horizontalAlign='center' className={styles.container}>
                        {product.product.packagings.map((pp) => this.renderPackagingCard(pp.packaging))}
                    </Stack>

                    {/*<Stack horizontal tokens={{ childrenGap: 15 }} verticalAlign='center' horizontalAlign='center'>
            <NavLink
                style={{ textDecoration: 'none' }}
                children={
                <Stack className={styles.package} verticalAlign='center' horizontalAlign='center'>
                    <img src={package1} alt='package1' className={styles.imgPackage} />
                    <Text className={styles.textTitle}>Package 1</Text>
                    <Text className={styles.textPrice}>(Rp. 100.000)</Text>
                </Stack>
                }
                to={config.url.selectBox.index}
            />
            <NavLink
                style={{ textDecoration: 'none' }}
                children={
                <Stack className={styles.package} verticalAlign='center' horizontalAlign='center'>
                    <img src={package1} alt='package1' className={styles.imgPackage} />
                    <Text className={styles.textTitle}>Package 2</Text>
                    <Text className={styles.textPrice}>(Rp. 100.000)</Text>
                </Stack>
                }
                to={config.url.selectBox.index}
            />
            </Stack>*/}
                    <FooterComponent context={context} steps={'selectPackage'} {...this.renderFooterComponent()} />
                </Stack>
            );
        }
    }
}
