import React from 'react';

// import office fabric components
import { Stack, Text, Spinner, Dialog, DialogType } from '@fluentui/react';

interface ILoadingDialogSurfaceProps {
  vector?: string;
  title?: string;
  secondaryText?: string;
  spinner?: boolean;
}

interface ILoadingDialogSurfaceState {}

export default class LoadingDialogSurface extends React.Component<
  ILoadingDialogSurfaceProps,
  ILoadingDialogSurfaceState
> {
  public constructor(props: ILoadingDialogSurfaceProps) {
    super(props);
    this.state = {};
  }

  public render() {
    const { vector, title, secondaryText, spinner } = this.props;
    return (
      <Dialog
        hidden={false}
        dialogContentProps={{
          type: DialogType.largeHeader,
          styles: { title: { padding: 0 } },
        }}
        modalProps={{ isBlocking: true }}
      >
        <Stack verticalAlign={'center'} horizontalAlign={'center'} tokens={{ childrenGap: 20 }}>
          {vector ? <img src={vector} /> : null}
          <Stack tokens={{ childrenGap: 10 }}>
            {title ? (
              <Text style={{ textAlign: 'center', fontWeight: 600 }} variant={'xLarge'}>
                {title}
              </Text>
            ) : null}
            {secondaryText ? <Text style={{ textAlign: 'center' }}>{secondaryText}</Text> : null}
          </Stack>
          {spinner ? <Spinner /> : null}
        </Stack>
      </Dialog>
    );
  }
}
