import axios from 'axios';
import moment from 'moment';
import Config from './../config';
import Cookies from 'js-cookie';
import { IPaginationResourceShort } from '../props/general';

export const refreshToken = async () => {
  // check if token is expired
  const expiry = Cookies.get('expiry');
  console.log(moment(expiry).format('DD/MMM/YYYY, HH:mm'));
  // console.log(Cookies.get('refresh'));
  if (moment(expiry).diff(moment()) < 0) {
    return await getToken();
  }
};

const clearCookies = () => {
  Object.keys(Cookies.get()).forEach(function (cookieName) {
    const neededAttributes = {};
    Cookies.remove(cookieName, neededAttributes);
  });
};

const convertPagination = (pagination: any): IPaginationResourceShort => {
  return {
    currentPage: pagination.current_page,
    nextPageUrl: pagination.next_page_url,
    prevPageUrl: pagination.prev_page_url,
  };
};

const getErrorData = (e: any) => {
  const message =
    e && e.response && e.response.data && e.response.data.data ? e.response.data.data : e.toString();
  const status = e && e.response && e.response.status ? e.response.status : 400;
  return { message, status };
};

const Request = {
  get: async (api: string, disableToken?: boolean) => {
    try {
      if (!disableToken) {
        await refreshToken();
      }
      const token = Cookies.get('token');
      const res = await axios.get(Config.apiHostname + api, {
        headers: {
          accept: 'application/json',
          authorization: disableToken ? '' : 'Bearer ' + token,
        },
      });
      const pagination = res.data.pagination ? convertPagination(res.data.pagination) : undefined;
      return pagination ? { data: res.data.data, pagination } : res.data.data;
    } catch (e) {
      const error = getErrorData(e);
      throw error;
    }
  },
  post: async (api: string, body?: any, contentType?: string, disableToken?: boolean) => {
    try {
      if (!disableToken) {
        await refreshToken();
      }
      const token = Cookies.get('token');
      const res = await axios.post(Config.apiHostname + api, body, {
        headers: {
          accept: 'application/json',
          authorization: disableToken ? '' : 'Bearer ' + token,
          'content-type': contentType || 'application/json',
        },
      });
      return res.data.data;
    } catch (e) {
      const error = getErrorData(e);
      throw error;
    }
  },
  put: async (api: string, body?: any, contentType?: string, disableToken?: boolean) => {
    try {
      if (!disableToken) {
        await refreshToken();
      }
      const token = Cookies.get('token');
      const res = await axios.put(Config.apiHostname + api, body, {
        headers: {
          accept: 'application/json',
          authorization: disableToken ? '' : 'Bearer ' + token,
          'content-type': contentType || 'application/json',
        },
      });
      return res.data.data;
    } catch (e) {
      const error = getErrorData(e);
      throw error;
    }
  },
  delete: async function (api: string, disableToken?: boolean) {
    try {
      if (!disableToken) {
        await refreshToken();
      }
      const token = Cookies.get('token');
      const res = await axios.delete(Config.apiHostname + api, {
        headers: {
          accept: 'application/json',
          authorization: disableToken ? '' : 'Bearer ' + token,
          'content-type': 'application/json',
        },
      });
      return res.data.data;
    } catch (e) {
      const error = getErrorData(e);
      throw error;
    }
  },
};

export const getToken = async () => {
  const refreshToken = Cookies.get('refresh');
  clearCookies();
  try {
    const result = await axios.post(
      Config.apiHostname + '/api/auth/refresh',
      {},
      {
        headers: {
          accept: 'application/json',
          authorization: refreshToken || '',
          'content-type': 'application/json',
        },
      }
    );

    Cookies.set('token', result.data.token.access_token);
    Cookies.set('refresh', result.data.token.refresh_token);
    Cookies.set(
      'expiry',
      moment()
        .add(result.data.token.expires_in - 60, 'seconds')
        .toISOString()
    );
    return result.data.token.access_token as string;
  } catch (error) {
    clearCookies();
    throw error;
  }
};

export default Request;
