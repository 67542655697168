import * as React from 'react';

// import ui
import { Stack, Text, DefaultButton, PrimaryButton, ActionButton, Icon, TooltipHost } from '@fluentui/react';

// import styles
import styles from './style.module.scss';

// import assets
import package1 from '../../assets/products/loure.png';
import { ProviderStore } from '../../../../context/global';
import FooterComponent from '../../../../components/footer';
import { NavLink } from 'react-router-dom';

import { IAvailableDataProps } from '../../../../props/general';
import { IProductResourceShort } from '../../../../props/products';
import GeneralService from '../../../../services/general';
import { TStep } from '../../index';
import { IPackagingResourceShort } from '../../../../props/packagings';
import { IFlavourResourceShort } from '../../../../props/flavours';
import { IOrderProductFlavourProps } from '../../../../props/orders/flavour';
import moment from 'moment';
import { IOrderProductProps } from '../../../../props/orders/product';

interface ISelectFlavourProps {
    data: IAvailableDataProps;
    context: ProviderStore;
    product: IOrderProductProps;
    onChangeStep(step: TStep, product: IOrderProductProps): void;
}

interface ISelectFlavourState {
    flavours: IOrderProductFlavourProps[];
}

export default class SelectFlavour extends React.Component<ISelectFlavourProps, ISelectFlavourState> {
    constructor(props: ISelectFlavourProps) {
        super(props);

        this.state = {
            flavours: this.props.product.flavours
        };
    }

    private renderFlavourCard = (flavour: IFlavourResourceShort) => {
        const related = this.state.flavours.find((fl) => fl.flavourId.toLowerCase() === flavour.id);
        let minQuantity = 0;
        if (related) {
            minQuantity = this.props.product.product.defaultFlavours.filter((df) => df.flavourId === related.flavourId && !df.replaceable).map((df) => df.maxQuantity).reduce((a, b) => a + b, 0);
        }


        return <Stack horizontal tokens={{childrenGap: 20}} verticalAlign={'center'} className={styles.card}>
            <Stack className={styles.image}>
                <img src={GeneralService.getFileUrl(flavour.images[0].url)} />
            </Stack>
            <Stack className={styles.details} grow={1} tokens={{ childrenGap: 10 }}>
                <Stack horizontal verticalAlign='center' horizontalAlign='space-between' wrap tokens={{childrenGap: 10}}>
                    <Text variant='large' style={{fontWeight: 600}}>{flavour.name}</Text>
                    <Stack horizontal verticalAlign='center' tokens={{childrenGap: 5}}>
                        {flavour.isSeasonal ? <TooltipHost content={"Seasonal Flavour"}><Icon iconName={"Snowflake"} className={styles.icon} /></TooltipHost> : null}
                        {flavour.isVegan ? <TooltipHost content={"Vegan Flavour"}><Icon iconName={"Squalls"} className={styles.icon} /></TooltipHost> : null}
                        {flavour.additionalPrice && flavour.additionalPrice > 0 ? <Text variant={'small'} className={styles.additionalPrice}>+ Rp. {GeneralService.getPriceWithSeparator(flavour.additionalPrice + '')}</Text> : null}
                    </Stack>
                </Stack>
                <Stack horizontal verticalAlign='center' tokens={{childrenGap: 20}}>
                    <DefaultButton disabled={!(related && related.quantity > minQuantity)} className={styles.qtyButton} iconProps={{iconName: "Remove"}} onClick={() => this._onQuantityChanged(flavour, -1)} />
                    <Text style={{fontWeight: 600}}>{related ? related.quantity : 0}</Text>
                    <PrimaryButton className={styles.qtyButton} disabled={this.isLimit()} iconProps={{iconName: "Add"}} onClick={() => this._onQuantityChanged(flavour, 1)} />
                </Stack>
            </Stack>
        </Stack>
    }

    private _onQuantityChanged = (flavour: IFlavourResourceShort, addition: number) => {
        let {flavours} = this.state;
        const idx = flavours.findIndex((fl) => fl.flavourId.toLowerCase() === flavour.id);
        if (idx > -1 && flavours[idx]) {
            flavours[idx].quantity += addition;
            flavours[idx].additionalPrice = (flavours[idx].flavour.additionalPrice || 0) * flavours[idx].quantity;
        } else {
            flavours.push({
                id: moment().toISOString(),
                additionalPrice: flavour.additionalPrice || 0,
                flavour,
                flavourId: flavour.id,
                orderProductId: this.props.product?.id || moment().toISOString(),
                quantity: addition
            });
        }

        this.setState({flavours: [...flavours]});
    }

    private _onContinue = () => {
        this.props.onChangeStep('selectTopping', { ...this.props.product, flavours: this.state.flavours.filter((fl) => fl.quantity > 0) });
    }

    private _onBack = () => {
        this.props.onChangeStep('selectPackaging', { ...this.props.product });
    }

    private totalQuantity = () => {
        const {flavours} = this.state;
        let quantity = 0;
        flavours.forEach((fl) => {quantity += fl.quantity});

        return quantity;
    }

    private canContinue = () => {
        const product = this.props.product?.product;

        if (product) {
            const totalQuantity = this.totalQuantity();
            return totalQuantity >= product.minQuantity;
        }
        return false;
    }

    private isLimit = () => {
        const product = this.props.product?.product;

        if (product) {
            const totalQuantity = this.totalQuantity();
            return totalQuantity >= product.maxQuantity;
        }
        return true;
    }

    private renderFooterComponent = () => {
        let leftItems: JSX.Element = <Stack>
            <ActionButton iconProps={{iconName: "Back"}} text={"Back"} onClick={this._onBack} />
        </Stack>

        const product = this.props.product.product;
        const isFixed = product.minQuantity === product.maxQuantity;
        let items: JSX.Element = isFixed ? <Stack horizontalAlign='center'>
            <Text variant='large' style={{fontWeight: 600}}>{this.totalQuantity()} out of {product.minQuantity}</Text>
            <Text>flavours selected</Text>
        </Stack> : <Stack horizontalAlign='center'>
            <Text>You've selected</Text>
            <Text variant='large' style={{fontWeight: 600}}>{this.totalQuantity()} Flavours</Text>
        </Stack>;
        
        let rightItems: JSX.Element = <Stack horizontal horizontalAlign='end'>
            <PrimaryButton text={"Continue"} disabled={!this.canContinue()} onClick={this._onContinue} />
        </Stack>

        return {leftItems, items, rightItems};
    }

    public render() {
        const { data, context, product } = this.props;
        if (product) {
            const relatedFlavours = data.flavours.filter((flavour) => {
                return (flavour.productTypes.map((pt) => pt.productType.id)).indexOf(product.product.typeId || '') > -1;
            })

            return (
                <Stack horizontalAlign='center' className={styles.flavours} tokens={{ childrenGap: 15 }}>
                    {product.product.minQuantity === product.product.maxQuantity ? <Text variant='xLarge' className='header'>SELECT {product.product.maxQuantity} FLAVOUR(S):</Text> : null}
                    {product.product.minQuantity !== product.product.maxQuantity ? <Text variant='xLarge' className='header'>SELECT UP TO {product.product.maxQuantity} FLAVOUR(S):</Text> : null}
                    <Stack grow={1} className={styles.container} tokens={{ childrenGap: 25 }}>
                        {relatedFlavours.map((flavour) => this.renderFlavourCard(flavour))}
                    </Stack>

                    {/*<Stack horizontal tokens={{ childrenGap: 15 }} verticalAlign='center' horizontalAlign='center'>
            <NavLink
                style={{ textDecoration: 'none' }}
                children={
                <Stack className={styles.package} verticalAlign='center' horizontalAlign='center'>
                    <img src={package1} alt='package1' className={styles.imgPackage} />
                    <Text className={styles.textTitle}>Package 1</Text>
                    <Text className={styles.textPrice}>(Rp. 100.000)</Text>
                </Stack>
                }
                to={config.url.selectBox.index}
            />
            <NavLink
                style={{ textDecoration: 'none' }}
                children={
                <Stack className={styles.package} verticalAlign='center' horizontalAlign='center'>
                    <img src={package1} alt='package1' className={styles.imgPackage} />
                    <Text className={styles.textTitle}>Package 2</Text>
                    <Text className={styles.textPrice}>(Rp. 100.000)</Text>
                </Stack>
                }
                to={config.url.selectBox.index}
            />
            </Stack>*/}
                    <FooterComponent context={context} steps={'selectPackage'} {...this.renderFooterComponent()} />
                </Stack>
            );
        }
    }
}
