import * as React from 'react';

// import ui
import { Stack, Text, DefaultButton, PrimaryButton, ActionButton } from '@fluentui/react';

// import styles
import styles from './style.module.scss';

// import assets
import package1 from '../../assets/products/loure.png';
import { ProviderStore } from '../../../../context/global';
import FooterComponent from '../../../../components/footer';
import { NavLink } from 'react-router-dom';

import { IAvailableDataProps } from '../../../../props/general';
import { IProductResourceShort } from '../../../../props/products';
import GeneralService from '../../../../services/general';
import { TStep } from '../../index';
import { IPackagingResourceShort } from '../../../../props/packagings';
import { IFlavourResourceShort } from '../../../../props/flavours';
import { IOrderProductFlavourProps } from '../../../../props/orders/flavour';
import moment from 'moment';
import { IOrderProductProps } from '../../../../props/orders/product';
import { IToppingResourceShortProps } from '../../../../props/toppings';
import { IOrderToppingResourceShortProps } from '../../../../props/orders/topping';

interface ISelectToppingProps {
    data: IAvailableDataProps;
    context: ProviderStore;
    product: IOrderProductProps;
    onChangeStep(step: TStep, product: IOrderProductProps): void;
}

interface ISelectToppingState {
    toppings: IOrderToppingResourceShortProps[];
}

export default class SelectTopping extends React.Component<ISelectToppingProps, ISelectToppingState> {
    constructor(props: ISelectToppingProps) {
        super(props);

        this.state = {
            toppings: []
        };
    }

    private _onContinue = () => {
        this.props.onChangeStep('submit', { ...this.props.product, toppings: this.state.toppings.filter((tp) => tp.quantity > 0) });
    }

    private _onBack = () => {
        this.props.onChangeStep('selectFlavour', { ...this.props.product });
    }

    private renderFooterComponent = () => {
        let leftItems: JSX.Element = <Stack>
            <ActionButton iconProps={{iconName: "Back"}} text={"Back"} onClick={this._onBack} />
        </Stack>
        
        let rightItems: JSX.Element = <Stack horizontal horizontalAlign='end'>
            <PrimaryButton text={"Add to Cart"} onClick={this._onContinue} />
        </Stack>

        return {leftItems, rightItems};
    }

    private _onQuantityChanged = (topping: IToppingResourceShortProps, addition: number) => {
        let {toppings} = this.state;
        const idx = toppings.findIndex((tp) => tp.toppingId.toLowerCase() === topping.id);
        if (idx > -1 && toppings[idx]) {
            toppings[idx].quantity += addition;
            toppings[idx].subtotal = (toppings[idx].topping.price || 0) * toppings[idx].quantity;
        } else {
            toppings.push({
                id: moment().toISOString(),
                orderProductId: this.props.product?.id || moment().toISOString(),
                price: topping.price || 0,
                topping,
                toppingId: topping.id,
                subtotal: topping.price,
                quantity: addition
            });
        }

        this.setState({toppings: [...toppings]});
    }

    private renderToppingCard = (topping: IToppingResourceShortProps) => {
        const related = this.state.toppings.find((tp) => tp.toppingId.toLowerCase() === topping.id);

        return <Stack horizontal tokens={{childrenGap: 20}} verticalAlign={'center'} className={styles.card}>
            <Stack className={styles.image}>
                <img src={GeneralService.getFileUrl(topping.images[0].url)} />
            </Stack>
            <Stack className={styles.details} grow={1} tokens={{ childrenGap: 10 }}>
                <Stack horizontal verticalAlign='center' horizontalAlign='space-between' wrap tokens={{childrenGap: 10}}>
                    <Text variant='large' style={{fontWeight: 600}}>{topping.name}</Text>
                    <Stack horizontal verticalAlign='center' tokens={{childrenGap: 5}}>
                        {topping.price && topping.price > 0 ? <Text variant={'small'} className={styles.additionalPrice}>+ Rp. {GeneralService.getPriceWithSeparator(topping.price + '')}</Text> : null}
                    </Stack>
                </Stack>
                <Stack horizontal verticalAlign='center' tokens={{childrenGap: 20}}>
                    <DefaultButton disabled={!related || (related && related.quantity < 1)} className={styles.qtyButton} iconProps={{iconName: "Remove"}} onClick={() => this._onQuantityChanged(topping, -1)} />
                    <Text style={{fontWeight: 600}}>{related ? related.quantity : 0}</Text>
                    <PrimaryButton className={styles.qtyButton} iconProps={{iconName: "Add"}} onClick={() => this._onQuantityChanged(topping, 1)} />
                </Stack>
            </Stack>
        </Stack>
    }

    public render() {
        const { data, context, product } = this.props;
        if (product) {
            return (
                <Stack horizontalAlign='center' className={styles.toppings} tokens={{ childrenGap: 15 }}>
                    <Text variant='xLarge' className='header'>SELECT ADDITIONAL TOPPING(S):</Text>
                    <Stack grow={1} className={styles.container} tokens={{ childrenGap: 25 }}>
                        {data.toppings.filter((tp) => product.product.typeId === tp.productTypeId).map((topping) => this.renderToppingCard(topping))}
                    </Stack>

                    <FooterComponent context={context} steps={'selectPackage'} {...this.renderFooterComponent()} />
                </Stack>
            );
        }
    }
}
