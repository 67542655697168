import * as React from 'react';

// import ui
import { ActionButton, DatePicker, PrimaryButton, Stack, Text } from '@fluentui/react';
import FooterComponent from '../../../../components/footer';
import { ProviderStore } from '../../../../context/global';
import { NavigateFunction, NavLink, useNavigate } from 'react-router-dom';
import styles from './style.module.scss';
import moment from 'moment';
import { TStep } from '../..';
import { ICheckoutDataProps } from '../../index';
import { IAvailableDataProps } from '../../../../props/general';

interface ICheckoutPageProps {
    context: ProviderStore;
    selected: Partial<ICheckoutDataProps>;
    onChangeStep(step: TStep, selected: Partial<ICheckoutDataProps>): void;
    navigate: NavigateFunction;
}

interface ICheckoutPageState {
    step: 'selectDeliveryDate' | 'selectDeliveryType' | 'selectedDeliveryMethod';
    deliveryDate?: string;
}

class SelectDeliveryDateComp extends React.Component<ICheckoutPageProps, ICheckoutPageState> {
    constructor(props: ICheckoutPageProps) {
        super(props);

        this.state = {
            step: 'selectDeliveryDate'
        };
    }

    private renderFooterComponent = () => {
        let leftItems: JSX.Element = <Stack>
            <NavLink to={"/cart"}><ActionButton iconProps={{ iconName: "Back" }} text={"Back"} /></NavLink>
        </Stack>

        let rightItems: JSX.Element = <Stack horizontal horizontalAlign='end'>
            <PrimaryButton text={"Continue"} disabled={!this.state.deliveryDate} onClick={() => this.props.onChangeStep('selectDeliveryType', {...this.props.selected, deliveryDate: moment(this.state.deliveryDate).toISOString()})} />
        </Stack>

        return { leftItems, rightItems };
    }

    private _onDateSelected = (date: Date | null | undefined) => {
        this.setState({deliveryDate: date ? moment(date).toISOString() : undefined});
    }

    public render() {
        const { context } = this.props;
        const { deliveryDate } = this.state;

        const isToday = deliveryDate && moment(deliveryDate).format("DDMMYYYY") === moment().format("DDMMYYYY");
        const isTomorrow = deliveryDate && moment(deliveryDate).format("DDMMYYYY") === moment().add(1, 'day').format("DDMMYYYY");

        return (
            <Stack className={styles.selectDeliveryDate} horizontalAlign='center' tokens={{ childrenGap: 15 }}>
                <Text variant='xLarge' className='header'>SELECT DELIVERY DATE</Text>
                <Stack tokens={{childrenGap: 20}} styles={{root: {width: '100%'}}}>
                    <Stack className={`${styles.card} ${isToday ? styles.active : ''}`} onClick={() => this._onDateSelected(moment().toDate())}>
                        <Text variant='large' style={{fontWeight: 600}}>Deliver Today</Text>
                        <Text>({moment().format("DD MMMM YYYY")})</Text>
                    </Stack>
                    <Stack className={`${styles.card} ${isTomorrow ? styles.active : ''}`} onClick={() => this._onDateSelected(moment().add(1, 'day').toDate())}>
                        <Text variant='large' style={{fontWeight: 600}}>Deliver Tomorrow</Text>
                        <Text>({moment().add(1, 'day').format("DD MMMM YYYY")})</Text>
                    </Stack>
                    <Stack className={`${styles.card} ${!isToday && !isTomorrow && deliveryDate ? styles.active : ''}`} tokens={{childrenGap: 5}}>
                        <Text variant='large' style={{fontWeight: 600}}>Select Other Date</Text>
                        <DatePicker value={!isToday && !isTomorrow && deliveryDate ? moment(deliveryDate).toDate() : undefined} formatDate={(date) => date ? moment(date).format("DD MMMM YYYY") : ""} onSelectDate={this._onDateSelected} />
                    </Stack>
                </Stack>
                <FooterComponent context={context} steps={'selectPackage'} {...this.renderFooterComponent()} />
            </Stack>
        );
    }
}

export default function SelectDeliveryDate(props: {data: IAvailableDataProps, context: ProviderStore, selected: Partial<ICheckoutDataProps>, onChangeStep(step: TStep, selected: Partial<ICheckoutDataProps>): void }) {
    const navigate = useNavigate();
    return <SelectDeliveryDateComp  {...props} navigate={navigate} />
}