import * as React from 'react';

// import ui
import { DefaultButton, Icon, PrimaryButton, Stack, Text } from '@fluentui/react';

// import assets
import img from '../../assets/backgrounds/auth-page.jpg';

// import styles
import styles from './styles.module.scss';

import { ProviderStore } from '../../context/global';
import FooterComponent from '../../components/footer';
import { IOrderProductProps } from '../../props/orders/product';
import CartService from '../../services/cart';
import { NavigateFunction, NavLink, useNavigate } from 'react-router-dom';

interface IGreetingsProps {
  context: ProviderStore;
  navigate: NavigateFunction;
}

interface IGreetingsState {
  loaded?: boolean;
  cart: IOrderProductProps[];
}

class GreetingsComp extends React.Component<IGreetingsProps, IGreetingsState> {
  constructor(props: IGreetingsProps) {
    super(props);

    this.state = {
      cart: []
    };
  }

  public componentDidMount = async () => {
    const cart = await CartService.retrieve();
    this.setState({ loaded: true, cart });
  }

  private _onReset = async () => {
    await CartService.reset();
    this.props.navigate('/package');
  }

  private renderFooterComponent = () => {
    const { cart } = this.state;
    const items: JSX.Element = <Stack tokens={{ childrenGap: 10 }} grow={1}>
      {cart.length > 0 ? <>
        <NavLink to={"/cart"}><PrimaryButton style={{ width: '100%' }} text={"Continue"} /></NavLink>
        <DefaultButton style={{ width: '100%' }} text={"Start Over"} onClick={this._onReset} />
      </> : null}
      {cart.length < 1 ? <NavLink to={"/package"}><PrimaryButton style={{ width: '100%' }} text={"Order Now"} /></NavLink> : null}
    </Stack>;
    return { items };
  }

  public render() {
    const { cart, loaded } = this.state;
    return (
      <Stack tokens={{ childrenGap: 40 }} className={styles.greetings}>
        <img src={img} alt='img' className={styles.image} />
        <Stack horizontalAlign='center' verticalAlign='center' tokens={{ childrenGap: 15 }}>
          <Text variant='xLargePlus' as={"h2"} className={styles.headerTitle}>Ciao</Text>
          {loaded && cart.length < 1 ? <Text variant='large' style={{ textAlign: 'center' }}>Please fill our order form to complete your order.</Text> : null}
          {loaded && cart.length > 0 ? <Text variant='large' style={{ textAlign: 'center' }}>Looks like you have an incomplete order. Would you like to continue?</Text> : null}
        </Stack>
        {loaded && cart.length > 0 ? <Stack tokens={{ childrenGap: 15 }}>
          <DefaultButton onClick={this._onReset}>
            <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
              <Icon iconName='Refresh' />
              <Text>Start Over</Text>
            </Stack>
          </DefaultButton>
          <NavLink to={'/cart'}>
            <PrimaryButton style={{width: '100%'}}>
                <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                  <Icon iconName='Forward' />
                  <Text style={{ color: '#fff' }}>Continue</Text>
                </Stack>
            </PrimaryButton>
          </NavLink>
        </Stack> : null}
        {loaded && cart.length < 1 ? <Stack tokens={{ childrenGap: 15 }}>
          <NavLink to={'/package'}>
            <PrimaryButton style={{width: '100%'}}>
                <Stack horizontal verticalAlign='center' tokens={{ childrenGap: 10 }}>
                  <Text style={{ color: '#fff' }}>Order Now</Text>
                </Stack>
            </PrimaryButton>
          </NavLink>
        </Stack> : null}
      </Stack>
    );
  }
}


export default function Greetings(props: { context: ProviderStore }) {
  const navigate = useNavigate();
  return <GreetingsComp {...props} navigate={navigate} />
}