import * as React from 'react';

// import ui
import { ActionButton, DatePicker, Icon, PrimaryButton, Stack, Text } from '@fluentui/react';
import FooterComponent from '../../../../components/footer';
import { ProviderStore } from '../../../../context/global';
import { NavigateFunction, NavLink, useNavigate } from 'react-router-dom';
import styles from './style.module.scss';
import moment from 'moment';
import { TStep } from '../..';
import { ICheckoutDataProps } from '../../index';
import { IAvailableDataProps } from '../../../../props/general';

interface ISelectDeliveryTypeProps {
    data: IAvailableDataProps;
    context: ProviderStore;
    selected: Partial<ICheckoutDataProps>;
    onChangeStep(step: TStep, selected: Partial<ICheckoutDataProps>): void;
}

interface ISelectDeliveryTypeState {}

export default class SelectDeliveryType extends React.Component<ISelectDeliveryTypeProps, ISelectDeliveryTypeState> {
    constructor(props: ISelectDeliveryTypeProps) {
        super(props);

        this.state = {};
    }

    private renderFooterComponent = () => {
        let leftItems: JSX.Element = <Stack>
            <ActionButton iconProps={{ iconName: "Back" }} text={"Back"} onClick={() => this.props.onChangeStep('selectDeliveryDate', {...this.props.selected})} />
        </Stack>

        return { leftItems };
    }

    private _onDeliveryTypeSelected = (type: 'single' | 'multiple') => {
        this.props.onChangeStep('deliveryDetails', {...this.props.selected, deliveryType: type});
    }

    public render() {
        const { context } = this.props;

        return (
            <Stack className={styles.selectDeliveryDate} horizontalAlign='center' tokens={{ childrenGap: 15 }}>
                <Text variant='xLarge' className='header'>SELECT DELIVERY DATE</Text>
                <Stack tokens={{childrenGap: 20}} styles={{root: {width: '100%'}}}>
                    <Stack className={styles.card} horizontal verticalAlign='center' tokens={{childrenGap: 20}} onClick={() => this._onDeliveryTypeSelected('single')}>
                        <Text variant={'xxLarge'}><Icon iconName={"Home"} /></Text>
                        <Stack>
                            <Text variant='large' style={{fontWeight: 600}}>Send to 1 Address</Text>
                            <Text>Send your order to 1 address and 1 recipients.</Text>
                        </Stack>
                    </Stack>
                    <Stack className={styles.card} horizontal verticalAlign='center' tokens={{childrenGap: 20}} onClick={() => this._onDeliveryTypeSelected('multiple')}>
                        <Text variant='xxLarge'><Icon iconName={"POI"} /></Text>
                        <Stack>
                            <Text variant='large' style={{fontWeight: 600}}>Send to multiple address</Text>
                            <Text>Feels like sharing Gelato? Set different address for evey pack of Gelato.</Text>
                        </Stack>
                    </Stack>
                </Stack>
                <FooterComponent context={context} steps={'selectPackage'} {...this.renderFooterComponent()} />
            </Stack>
        );
    }
}