import * as React from 'react';

// import ui
import { IconButton, Stack, Text } from '@fluentui/react';

// import styles
import styles from './styles.module.scss';
import { ProviderStore } from '../../context/global';
import { NavLink } from 'react-router-dom';
import { IOrderProductProps } from '../../props/orders/product';
import CartService from '../../services/cart';

interface IMenubarComponentProps {
  context: ProviderStore;
}

interface IMenubarComponentState {
  cart: IOrderProductProps[];
}

export default class MenubarComponent extends React.Component<
  IMenubarComponentProps,
  IMenubarComponentState
> {
  constructor(props: IMenubarComponentProps) {
    super(props);

    this.state = {
      cart: []
    };
  }

  public componentDidMount = async () => {
    const cart = await CartService.retrieve();
    this.setState({cart});
  }

  public render() {
    const { context } = this.props;
    const { cart } = this.state;

    return (
      <Stack className={`${styles.container} lou-of-container`} verticalAlign={'center'}>
        <Stack className='lou-of-content' horizontal verticalAlign='center' horizontalAlign='space-between'>
          <Stack>
            <Text className={`${styles.text} ${styles.headerTitle}`} as={"h1"}>{'Order Form'}</Text>
          </Stack>
          <Stack>
            <NavLink
              children={
                <IconButton
                  iconProps={{ iconName: 'ShoppingCart' }}
                  styles={{ root: { color: '#ffffff' }, icon: { fontSize: 24 } }}
                  className={styles.iconNotification}
                  children={
                    <Stack className={styles.dot} verticalAlign='center' horizontalAlign='center'>
                      <Text variant='small' className={styles.text}>
                        {cart.length}
                      </Text>
                    </Stack>
                  }
                />
              }
              to={'/cart'}
            />
          </Stack>
        </Stack>
      </Stack>
    );
  }
}
