interface IReturnProps {
  error: boolean;
  message?: string;
  code?: string;
}

interface IAllowedProps {
  min?: number;
  max?: number;
  maxSize?: number;
  maxChars?: number;
  allowedFormats?: string[];
}

type TValidationType = 'required' | 'email' | 'number' | 'file' | 'files' | 'phoneNumber' | 'limit';

const validationService = {
  combination: (value: any, validations: TValidationType[], props: IAllowedProps): IReturnProps => {
    let result: IReturnProps = { error: false };
    for (var ctr = 0; ctr < validations.length; ctr++) {
      const validation = validations[ctr];
      if (validation === 'required') {
        result = validationService.required(value);
      } else if (validation === 'limit') {
        result = validationService.limit(value, props.maxChars !== undefined ? props.maxChars : 255);
      } else if (validation === 'email') {
        result = validationService.email(value);
      } else if (validation === 'phoneNumber') {
        result = validationService.phoneNumber(value);
      } else if (validation === 'number') {
        result = validationService.number(value, props.min, props.max);
      } else if (validation === 'file') {
        result = validationService.file(value, props.maxSize, props.allowedFormats);
      } else if (validation === 'files') {
        result = validationService.files(value, props.maxSize, props.allowedFormats);
      }

      if (result && result.error) {
        break;
      }
    }
    return result;
  },
  required: (value?: any): IReturnProps => {
    if (!value) {
      return { error: true, message: 'Value is required and could not be empty.', code: 'required' };
    } else if (value && typeof value === 'string' && value.trim() === '') {
      return { error: true, message: 'Value is required and could not be empty.', code: 'required' };
    } else if (value && Array.isArray(value) && value.length < 1) {
      return { error: true, message: 'Value is required and could not be empty.', code: 'required' };
    }

    return { error: false };
  },
  limit: (value: string, max: number) => {
    if (value && value.trim() && value.length > max) {
      return {
        error: true,
        message: `Value exceed maximum characters limit (${
          value.trim().length
        } from allowed ${max} characters)`,
        code: 'required',
      };
    }

    return { error: false };
  },
  email: (email: string): IReturnProps => {
    const regex =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!regex.test(email)) {
      return { error: true, message: 'Email address is not valid.', code: 'invalid' };
    }
    return { error: false };
  },
  phoneNumber: (phoneNumber: string): IReturnProps => {
    const regex = /^[0-9]{0,15}$/g;
    if (!regex.test(phoneNumber)) {
      return { error: true, message: 'Phone number is not valid.', code: 'invalid' };
    }
    return { error: false };
  },
  number: (number: string, min?: number, max?: number): IReturnProps => {
    const regex = /^\d+$/;
    if (!regex.test(number)) {
      return { error: true, message: 'Value is not a valid number.', code: 'invalid' };
    } else if (min && max && (parseInt(number) < min || parseInt(number) > max)) {
      return {
        error: true,
        message: `Allowed value could only between ${min} and ${max}`,
        code: 'exceedLimit',
      };
    } else if (min && parseInt(number) < min) {
      return { error: true, message: `Allowed value could not be less than ${min}`, code: 'exceedLimit' };
    } else if (max && parseInt(number) > max) {
      return { error: true, message: `Allowed value could not be more than ${max}`, code: 'exceedLimit' };
    }
    return { error: false };
  },
  file: (file: any, maxSize?: number, allowedFormats?: string[]): IReturnProps => {
    if (file && maxSize && file.size > maxSize) {
      return {
        error: true,
        message: `File could not exceed maximum allowed size: ${maxSize / 1000000}MB`,
        code: 'exceedLimit',
      };
    } else if (file && allowedFormats) {
      allowedFormats = allowedFormats.map((format) => {
        return format.toLowerCase();
      });
      if (allowedFormats.indexOf(file.type.toLowerCase()) < 0) {
        return {
          error: true,
          message: `File format is not supported. Supported format(s) are ${allowedFormats.join(', ')}`,
          code: 'invalid',
        };
      }
    }
    return { error: false };
  },
  files: (files: any, maxSize?: number, allowedFormats?: string[]): IReturnProps => {
    let message: IReturnProps = { error: false };
    files = Array.from(files);

    for (var ctr = 0; ctr < files.length; ctr++) {
      const file = files[ctr];
      if (!file.url && !file.deleted) {
        if (maxSize && file.size > maxSize) {
          message = {
            error: true,
            message: `File "${file.name}" exceed maximum allowed size: ${maxSize / 1000000}MB`,
            code: 'exceedLimit',
          };
          break;
        } else if (allowedFormats) {
          allowedFormats = allowedFormats.map((format) => {
            return format.toLowerCase();
          });
          if (allowedFormats.indexOf(file.type.toLowerCase()) < 0) {
            message = {
              error: true,
              message: `File "${
                file.name
              }" format is not supported. Supported format(s) are ${allowedFormats.join(', ')}`,
              code: 'invalid',
            };
            break;
          }
        }
      }
    }
    return message;
  },
};

export default validationService;
