import GeneralConfig from "../config";

const GeneralService = {
  generateRandomText: (length: number) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  },
  getFileUrl: (url: string) => {
    return GeneralConfig.assetsHostname + '/' + url;
  },
  getErrorMessage: (e: any) => {
    return e.toString();
  },
  getInitial: function (text: string) {
    let splittedText = text.split(' ');
    let initial = '';
    if (splittedText.length > 1) {
      initial = splittedText[0][0] + splittedText[1][0];
    } else if (splittedText[0].length > 1) {
      initial = splittedText[0][0] + splittedText[0][1];
    } else if (splittedText[0].length > 0) {
      initial = splittedText[0];
    } else {
      throw { error: { status: 400, message: 'Text cannot be empty.' } };
    }

    return initial.toUpperCase();
  },
  getParameterByName: function (name: string, url?: string) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    let regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  },
  getPriceWithSeparator: (price: string) => {
    price = price.split('.').join('');
    price = price.split(',').join('');
    return price.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  },

  randomiseColour: (opactiy: number) => {
    return `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(
      Math.random() * 255
    )}, ${opactiy + ''})`;
  },
  getFileFormatIcon: (fileName: string) => {
    const format = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (format === 'png' || format === 'jpg' || format === 'jpeg' || format === 'gif') {
      return 'FileImage';
    } else if (format === 'doc' || format === 'docx' || format === 'docs') {
      return 'WordDocument';
    } else if (format === 'xls' || format === 'xlsx' || format === 'csv') {
      return 'ExcelDocument';
    } else if (format === 'ppt' || format === 'pptx' || format === 'csv' || format === 'tsv') {
      return 'PowerPointDocument';
    } else if (
      format === 'mp4' ||
      format === 'mpeg4' ||
      format === 'avi' ||
      format === 'mov' ||
      format === 'wmv' ||
      format === 'flv' ||
      format === 'avchd' ||
      format === 'webm'
    ) {
      return 'Video';
    } else if (format === 'zip' || format === 'rar') {
      return 'ZipFolder';
    } else if (format === 'pdf') {
      return 'PDF';
    } else if (format === 'txt') {
      return 'TextDocument';
    } else if (format === 'html' || format === 'aspx') {
      return 'FileHTML';
    } else {
      return 'Page';
    }
  },
  getTextFieldCursorXY: (input: any, selectionPoint: number) => {
    const { offsetLeft: inputX, offsetTop: inputY } = input;
    // create a dummy element that will be a clone of our input
    const div = document.createElement('div');
    // get the computed style of the input and clone it onto the dummy element
    const copyStyle: any = getComputedStyle(input);
    for (const prop of copyStyle) {
      div.style[prop] = copyStyle[prop];
    }
    // we need a character that will replace whitespace when filling our dummy element if it's a single line <input/>
    const swap = '.';
    const inputValue = input.tagName === 'INPUT' ? input.value.replace(/ /g, swap) : input.value;
    // set the div content to that of the textarea up until selection
    const textContent = inputValue.substr(0, selectionPoint);
    // set the text content of the dummy element div
    div.textContent = textContent;
    if (input.tagName === 'TEXTAREA') div.style.height = 'auto';
    // if a single line input then the div needs to be single line and not break out like a text area
    if (input.tagName === 'INPUT') div.style.width = 'auto';
    // create a marker element to obtain caret position
    const span = document.createElement('span');
    // give the span the textContent of remaining content so that the recreated dummy element is as close as possible
    span.textContent = inputValue.substr(selectionPoint) || '.';
    // append the span marker to the div
    div.appendChild(span);
    // append the dummy element to the body
    document.body.appendChild(div);
    // get the marker position, this is the caret position top and left relative to the input
    const { offsetLeft: spanX, offsetTop: spanY } = span;
    // lastly, remove that dummy element
    // NOTE:: can comment this out for debugging purposes if you want to see where that span is rendered
    document.body.removeChild(div);
    // return an object with the x and y of the caret. account for input positioning so that you don't need to wrap the input
    return {
      x: inputX + spanX,
      y: inputY + spanY,
    };
  },
};

export default GeneralService;
