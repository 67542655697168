import * as React from 'react';
import {NavigateFunction, useNavigate } from 'react-router-dom';

// import ui
import { Stack } from '@fluentui/react';

// import styles

// import assets
import { ProviderStore } from '../../context/global';

import { IAvailableDataProps } from '../../props/general';
import SelectProduct from './subpages/product/index';
import SelectPackaging from './subpages/packaging';
import SelectFlavour from './subpages/flavours';
import { IOrderProductProps } from '../../props/orders/product';
import SelectTopping from './subpages/toppings/index';
import CartService from '../../services/cart';

export type TStep = 'selectProduct' | 'selectPackaging' | 'selectFlavour' | 'selectTopping' | 'submit';

interface ISelectPackageProps {
  data: IAvailableDataProps;
  navigate: NavigateFunction;
  context: ProviderStore;
}

interface ISelectPackageState {
  step: TStep;
  product?: IOrderProductProps;
}

class SelectPackageComp extends React.Component<ISelectPackageProps, ISelectPackageState> {
  
  constructor(props: ISelectPackageProps) {
    super(props);

    this.state = {
      step: 'selectProduct'
    };
  }

  private calculatePrice = (product: IOrderProductProps) => {
    // for packaged price
    if (product.product.maxQuantity === product.product.minQuantity) {
      // set product price as default
      let price = parseInt(product.product.price + "");

      // add price with pacakging price
      price += (parseInt((product.packaging.additionalPrice || 0) + ""));

      // add price with flavour price
      product.flavours.forEach((flavour) => {
        price += (parseInt((flavour.additionalPrice || 0) + ""));
      });

      product.toppings.forEach((topping) => {
        price += (parseInt((topping.subtotal || 0) + ""));
      });

      return price;
    } else {
      // for price per item
      let price = 0;

      // add price with pacakging price
      price += (parseInt((product.packaging.additionalPrice || 0) + ""));

      product.flavours.forEach((flavour) => {
        price += parseInt(product.product.price + "") * flavour.quantity;
        price += parseInt(flavour.additionalPrice + "");
      });

      product.toppings.forEach((topping) => {
        price += parseInt(topping.subtotal + "")
      });

      return price;
    }
  }

  private _onStepChanged = async (step: TStep, product: IOrderProductProps) => {
    if (step === 'submit') {
      product.price = this.calculatePrice(product);
      await CartService.add(product);
      this.props.navigate('/cart');
    } else {
      this.setState({step, product});
    }
  }

  public render() {
    const { data, context } = this.props;
    const { step, product } = this.state;

    return (
      <Stack styles={{root: {width: '100%'}}}>
        {step === 'selectProduct' || !product ? <SelectProduct data={data} context={context} product={product} onChangeStep={this._onStepChanged} /> : null}
        {step === 'selectPackaging' && product ? <SelectPackaging data={data} context={context} product={product} onChangeStep={this._onStepChanged} /> : null}
        {step === 'selectFlavour' && product ? <SelectFlavour data={data} context={context} product={product} onChangeStep={this._onStepChanged} /> : null}
        {step === 'selectTopping' && product ? <SelectTopping data={data} context={context} product={product} onChangeStep={this._onStepChanged} /> : null}
      </Stack>
    );
  }
}

export default function SelectPackage(props: {data: IAvailableDataProps, context: ProviderStore}) {
  const navigate = useNavigate();
  return <SelectPackageComp  {...props} navigate={navigate} />
}